<template>
  <CRow>
    <CCol md="12">
      <CCard>
        <CCardBody>
          <CTabs>
            <CTab title="Detail Permohonan" active>
              <CRow>
                <div class="col-12 my-3">
                  <h3 class="mb-0">
                    Detail Permohonan {{ detail_data.kode_permohonan }}
                  </h3>
                </div>
                <div class="col-md">
                  <table class="w-100" border="0">
                    <tr>
                      <th style="width: 250px">No. Surat Permohonan</th>
                      <td style="width: 15px">:</td>
                      <!-- <td>{{ detail_data.permohonan.pr_nomor_surat }}</td> -->
                    </tr>
                    <tr>
                      <th style="width: 250px">Tanggal Surat</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_tanggal }}
                      </td>
                    </tr>
                    <tr>
                      <th>NIB</th>
                      <td style="width: 15px">:</td>
                      <td>
                        <template>
                          <div
                            v-if="
                              detail_data.perusahaan &&
                              detail_data.perusahaan.tipe_pemohon_id != 2
                            "
                          >
                            {{ detail_data.perusahaan.nib }}
                          </div>
                          <div v-else>Data Tidak Tersedia</div>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>Jenis Pelaku Usaha</th>
                      <td style="width: 15px">:</td>
                      <td>
                        <template>
                          <div
                            v-if="
                              detail_data.perusahaan &&
                              detail_data.perusahaan.jenis_pelaku_usaha
                            "
                          >
                            {{ detail_data.perusahaan.jenis_pelaku_usaha }}
                          </div>
                          <div v-else>Data Tidak Tersedia</div>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>Jenis Perseroan</th>
                      <td style="width: 15px">:</td>
                      <td>
                        <template>
                          <div
                            v-if="
                              detail_data.perusahaan &&
                              detail_data.perusahaan.jenis_perseroan
                            "
                          >
                            {{ detail_data.perusahaan.jenis_perseroan }}
                          </div>
                          <div v-else>Data Tidak Tersedia</div>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>Nama Perusahaan</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.perusahaan">
                        {{ detail_data.perusahaan.p_nama }}
                      </td>
                    </tr>
                    <tr>
                      <th>Email Perusahaan</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.perusahaan">
                        {{ detail_data.perusahaan.p_email }}
                      </td>
                    </tr>
                    <tr>
                      <th>Penanggung Jawab Perusahaan</th>
                      <td style="width: 15px">:</td>
                      <td>
                        <!-- {{detail_data.perusahaan.p_penanggung_jawab}}  -->
                        <div
                          class="btn btn-info btn-sm"
                          v-on:click="modalPenanggungJawab = true"
                        >
                          <i class="fa fa-eye"></i> List Penanggung Jawab
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Telp Perusahaan</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.perusahaan">
                        {{ detail_data.perusahaan.p_telp }}
                      </td>
                    </tr>
                    <!-- <tr>
											<th style="width: 250px">Modul </th>
											<td style="width: 15px"> : </td>
											<td>{{detail_data.modules.m_nama}} </td>
										</tr> -->
                    <tr>
                      <th style="width: 250px">Perihal</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_perihal }}
                      </td>
                    </tr>

                    <tr>
                      <th style="width: 250px">Negara Asal Benih</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.negara_asal">
                        {{ detail_data.negara_asal.n_nama }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Negara Pengirim</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.negara_pengirim">
                        {{ detail_data.negara_pengirim.n_nama }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Nama Pengirim</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_nama_pengirim }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Alamat Pengirim</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_alamat_pengirim }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Pekerjaan Pengirim</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_pekerjaan_pengirim }}
                      </td>
                    </tr>

                    <tr>
                      <th style="width: 250px">Tempat Pemasukan</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.pelabuhan">
                        {{ detail_data.pelabuhan.pl_code }} -
                        {{ detail_data.pelabuhan.pl_nama }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Tujuan Pemasukan Benih</th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.tujuan_penggunaan_benih">
                        {{ detail_data.tujuan_penggunaan_benih.tpb_nama }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">
                        Keterangan Tujuan Pemasukan Benih
                      </th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_keterangan }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th style="width: 250px">
                        No. Rekomendasi Badan Karantina
                      </th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_nomor_sr }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">
                        Tgl. Rekomendasi Badan Karantina
                      </th>
                      <td style="width: 15px">:</td>
                      <td v-if="detail_data.permohonan">
                        {{ detail_data.permohonan.pr_tanggal_sr }}
                      </td>
                    </tr> -->
                    <tr>
                      <th>Status</th>
                      <td style="width: 15px">:</td>
                      <td>
                        <template>
                          <div
                            class="badge badge-success"
                            v-if="
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan &&
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.st_nama == 'Selesai'
                            "
                          >
                            Selesai
                          </div>
                          <div
                            class="badge badge-danger"
                            v-else-if="
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan &&
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.st_nama == 'Dibatalkan'
                            "
                          >
                            Permohonan Dibatalkan
                          </div>
                          <div
                            class="badge badge-warning"
                            v-else-if="
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan &&
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.st_nama ==
                                'Menunggu Perbaikan'
                            "
                          >
                            {{
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.st_nama
                            }}
                            oleh Pemohon
                          </div>
                          <div
                            class="badge badge-primary"
                            v-else-if="
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan &&
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.st_nama ==
                                'Evaluasi Dokumen Teknis'
                            "
                          >
                            <template>
                              <!-- <div v-if="permohonan_status.status_permohonan_terbaru.status_permohonan.roles.ditjen == 'dirjen perkebunan'">
																Menunggu {{permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama}} oleh <span class="text-uppercase">{{permohonan_status.status_permohonan_terbaru.status_permohonan.roles.name}}</span>
															</div>
															<div v-else> -->
                              Menunggu
                              {{
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan.st_nama
                              }}
                              oleh
                              <span class="text-uppercase">{{
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan.roles.ditjen
                              }}</span>
                              <!-- </div> -->
                            </template>
                          </div>
                          <div v-else>
                            <template>
                              <div
                                class="badge badge-info"
                                v-if="
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan &&
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.roles.ditjen ==
                                    'dirjen perkebunan'
                                "
                              >
                                Menunggu
                                {{
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama
                                }}
                                oleh
                                <span class="text-uppercase">{{
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.roles.name
                                }}</span>
                              </div>
                              <div class="badge badge-info" v-else>
                                Menunggu
                                {{
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama
                                }}
                                oleh
                                <span class="text-uppercase">{{
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.roles.ditjen
                                }}</span>
                              </div>
                            </template>
                          </div>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Data benih</th>
                      <td style="width: 15px">:</td>
                      <td></td>
                    </tr>
                  </table>
                </div>
                <div
                  class="text-center col-md-4"
                  v-if="
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan &&
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.st_nama == 'Selesai'
                  "
                >
                  <img src="img/approved.jpg" style="width: 150px" alt="" />
                  <br /><br />
                </div>
                <div class="col-12">
                  <template>
                    <div
                      v-if="
                        (session_data.roles_id ==
                          permohonan_status.status_permohonan_terbaru
                            .status_permohonan.roles_id ||
                          session_data.roles_id == 1) &&
                        permohonan_status.status_permohonan_terbaru
                          .status_permohonan.is_edit_benih &&
                        ((permohonan_status.status_permohonan_terbaru
                          .status_permohonan.is_proses_by_komoditas &&
                          session_data.tanaman_kategori_id ==
                            detail_data.tujuan_penggunaan_benih
                              .tanaman_kategori_id) ||
                          !permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_proses_by_komoditas ||
                          session_data.roles_id == 1)
                      "
                    >
                      <table
                        class="table table-bordered table-sm"
                        id="table-benih"
                      >
                        <thead class="bg-sapphire">
                          <tr>
                            <th
                              v-if="
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan &&
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan.is_persetujuan_benih ==
                                  true
                              "
                            >
                              Persetujuan benih
                            </th>
                            <th>Nama Varietas</th>
                            <th>Jumlah</th>
                            <th>Jumlah Disetujui</th>
                            <th>Nilai</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              value_rows_benih_raw, key_rows_benih_raw
                            ) in rows_benih_raw"
                            :key="key_rows_benih_raw"
                            v-bind:class="{
                              'bg-danger': !value_rows_benih_raw.is_aropt,
                            }"
                          >
                            <td
                              v-if="
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan &&
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan.is_persetujuan_benih ==
                                  true
                              "
                            >
                              <input
                                type="checkbox"
                                v-bind:checked="{
                                  checked: value_rows_benih_raw.is_aropt,
                                }"
                                v-model="value_rows_benih_raw.is_aropt"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_varietas"
                                disabled=""
                              />
                              <!-- <v-select disabled="" :options="['Varietas','Klon','Hibrida']" placeholder="Pilih Varietas Klon Hibrida" v-model="value_rows_benih_raw.pb_varietas"></v-select>	 -->
                            </td>
                            <td
                              v-if="
                                detail_data.permohonan.pr_kategori ==
                                'Tanaman Pangan'
                              "
                            >
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_nama_induk"
                                disabled=""
                              />
                              <!-- <v-select disabled="" :options="['Varietas','Klon','Hibrida']" placeholder="Pilih Varietas Klon Hibrida" v-model="value_rows_benih_raw.pb_varietas"></v-select>	 -->
                            </td>
                            <td>
                              <input
                                disabled=""
                                type="number"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_jumlah"
                              />
                            </td>
                            <td>
                              <input
                                :disabled="!value_rows_benih_raw.is_aropt"
                                type="number"
                                class="form-control"
                                v-model="
                                  value_rows_benih_raw.pb_jumlah_disetujui
                                "
                              />
                            </td>
                            <td>
                              <input
                                disabled=""
                                type="number"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_nilai_total"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <small
                        v-if="
                          permohonan_status.status_permohonan_terbaru
                            .status_permohonan &&
                          permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_persetujuan_benih == true
                        "
                      >
                        *) Hapus centang pada benih yang ingin dibatalkan.
                      </small>
                      <div
                        class="btn btn-primary pull-right"
                        v-on:click="saveDataBenih"
                      >
                        <i class="fa fa-save"></i> Simpan Data Benih
                      </div>
                    </div>
                    <div v-else>
                      <table
                        class="table table-bordered table-sm w-100"
                        id="table-benih"
                      >
                        <thead class="bg-sapphire">
                          <tr>
                            <th style="width: 15px">No</th>
                            <th
                              style="width: 15px"
                              v-if="
                                (session_data.roles_id ==
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.roles_id ||
                                  session_data.roles_id == 1) &&
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan.is_persetujuan_benih ==
                                  true &&
                                ((permohonan_status.status_permohonan_terbaru
                                  .status_permohonan.is_proses_by_komoditas &&
                                  session_data.tanaman_kategori_id ==
                                    detail_data.tujuan_penggunaan_benih
                                      .tanaman_kategori_id) ||
                                  !permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.is_proses_by_komoditas ||
                                  session_data.roles_id == 1)
                              "
                            >
                              Persetujuan Benih
                            </th>
                            <th>Nama Varietas</th>
                            <th>Jumlah</th>
                            <th>Jumlah Disetujui</th>
                            <th>Nilai</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-if="detail_data.permohonan.permohonan_benih"
                          >
                            <tr
                              v-for="(value_benih, index_benih) in detail_data
                                .permohonan.permohonan_benih"
                              v-bind:class="{
                                'bg-danger': !value_benih.is_aropt,
                              }"
                              :key="index_benih"
                            >
                              <td>{{ index_benih + 1 }}</td>
                              <td
                                v-if="
                                  (session_data.roles_id ==
                                    permohonan_status.status_permohonan_terbaru
                                      .status_permohonan.roles_id ||
                                    session_data.roles_id == 1) &&
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.is_persetujuan_benih ==
                                    true &&
                                  ((permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.is_proses_by_komoditas &&
                                    session_data.tanaman_kategori_id ==
                                      detail_data.tujuan_penggunaan_benih
                                        .tanaman_kategori_id) ||
                                    !permohonan_status.status_permohonan_terbaru
                                      .status_permohonan
                                      .is_proses_by_komoditas ||
                                    session_data.roles_id == 1)
                                "
                              >
                                <input
                                  type="checkbox"
                                  v-bind:checked="{
                                    checked: value_benih.is_aropt,
                                  }"
                                  v-model="rows_benih_raw[index_benih].is_aropt"
                                />
                              </td>
                              <td>{{ value_benih.pb_varietas }}</td>
                              <td>{{ value_benih.pb_jumlah }}</td>
                              <td>{{ value_benih.pb_jumlah_disetujui }}</td>
                              <td>{{ value_benih.pb_nilai_total }}</td>
                            </tr>
                            <tr style="height: 20px; background-color: #f5f5f5">
                              <td colspan="10"></td>
                            </tr>
                            <tr style="font-weight: bold">
                              <td colspan="2">Total Jumlah Benih diajukan :</td>
                              <td colspan="8">
                                {{ totalPbJumlah }}
                                {{
                                  detail_data.permohonan.permohonan_benih[0]
                                    .satuan.s_nama
                                }}
                              </td>
                            </tr>
                            <tr style="font-weight: bold">
                              <td colspan="2">
                                Total Jumlah Benih disetujuai :
                              </td>
                              <td colspan="8">
                                {{ totalPbJumlahDisetujui }}
                                {{
                                  detail_data.permohonan.permohonan_benih[0]
                                    .satuan.s_nama
                                }}
                              </td>
                            </tr>
                            <tr style="font-weight: bold">
                              <td colspan="2">
                                Total Nilai (Total Harga dalam USD) :
                              </td>
                              <td colspan="8">{{ totalPbNilaiTotal }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <small
                        v-if="
                          (session_data.roles_id ==
                            permohonan_status.status_permohonan_terbaru
                              .status_permohonan.roles_id ||
                            session_data.roles_id == 1) &&
                          (permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_edit_benih ||
                            permohonan_status.status_permohonan_terbaru
                              .status_permohonan.is_persetujuan_benih)
                        "
                      >
                        *) Hapus centang pada benih yang terkena aropt.
                      </small>
                      <div
                        class="btn btn-primary pull-right"
                        v-on:click="saveDataBenih"
                        v-if="
                          (session_data.roles_id ==
                            permohonan_status.status_permohonan_terbaru
                              .status_permohonan.roles_id ||
                            session_data.roles_id == 1) &&
                          (permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_edit_benih ||
                            permohonan_status.status_permohonan_terbaru
                              .status_permohonan.is_persetujuan_benih)
                        "
                      >
                        <i class="fa fa-save"></i> Simpan Data Benih
                      </div>
                    </div>
                  </template>
                  <table
                    class="w-100"
                    border="0"
                    v-if="detail_data.tujuan_penggunaan_benih.id == 3"
                  >
                    <tr>
                      <th style="width: 250px">Kelompok Tani</th>
                      <td style="width: 15px">:</td>
                      <td></td>
                    </tr>
                  </table>
                  <table
                    class="table table-bordered table-sm"
                    v-if="detail_data.tujuan_penggunaan_benih.id == 3"
                  >
                    <thead class="bg-sapphire">
                      <tr>
                        <th style="width: 15px">No</th>
                        <th>Nama Kelompok Tani</th>
                        <th>NIK</th>
                        <th>NO. HP</th>
                        <th>Titik Koordinat</th>
                        <th>Keb Benih</th>
                        <th>Luas Lahan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template>
                        <tr
                          v-if="
                            detail_data.permohonan.permohonan_kelompok_tani
                              .length == 0
                          "
                        >
                          <td colspan="6">
                            <i class="fa fa-times"></i> Data Tidak Tersedia
                          </td>
                        </tr>
                        <tr
                          v-for="(
                            value_row_kelompok_tani, key_row_kelompok_tani
                          ) in detail_data.permohonan.permohonan_kelompok_tani"
                          :key="key_row_kelompok_tani"
                          v-else
                        >
                          <td>{{ key_row_kelompok_tani + 1 }}</td>
                          <td>
                            {{ value_row_kelompok_tani.nama_kelompok_tani }}
                          </td>
                          <td>
                            {{ value_row_kelompok_tani.nik }}
                          </td>
                          <td>
                            {{ value_row_kelompok_tani.no_hp }}
                          </td>
                          <td>
                            {{ value_row_kelompok_tani.titik_koordinat }}
                          </td>
                          <td>
                            {{ value_row_kelompok_tani.kebun_benih }}
                          </td>
                          <td>
                            {{ value_row_kelompok_tani.luas_lahan }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <CCol md="12">
                  <hr />
                  <h3 class="mb-0 my-3">Dokumen Permohonan</h3>
                  <table class="table table-sm table-bordered">
                    <thead class="bg-dark text-hite">
                      <tr class="text-center">
                        <th style="width: 15px">No</th>
                        <th>Dokumen Permohonan</th>
                        <th style="width: 100px">File</th>
                        <th
                          style="width: 200px"
                          v-if="
                            permohonan_status.status_permohonan_terbaru
                              .status_permohonan.is_verifikasi_dokumen &&
                            (session_data.roles_id ==
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.roles_id ||
                              session_data.roles_id == 1) &&
                            ((permohonan_status.status_permohonan_terbaru
                              .status_permohonan.is_proses_by_komoditas &&
                              session_data.tanaman_kategori_id ==
                                detail_data.tujuan_penggunaan_benih
                                  .tanaman_kategori_id) ||
                              !permohonan_status.status_permohonan_terbaru
                                .status_permohonan.is_proses_by_komoditas ||
                              session_data.roles_id == 1)
                          "
                        >
                          Aksi
                        </th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          value_doc_persyaratan, index_doc_persyaratan
                        ) in sortedPermohonanDokumen"
                        :key="index_doc_persyaratan"
                      >
                        <td>{{ index_doc_persyaratan + 1 }}</td>
                        <td>
                          {{
                            value_doc_persyaratan.dokumen_permohonan
                              .dokumen_persyaratan_permohonan.dpp_nama
                          }}
                        </td>
                        <td>
                          <!-- <template>
                            <div
                              v-if="value_doc_persyaratan.file"
                              target="_blank"
                              class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                              v-on:click="getFile(value_doc_persyaratan.file)"
                            >
                              <i class="fa fa-eye"></i> Lihat
                            </div>
                            <div v-else>Data tidak tersedia</div>
                          </template> -->
                          <!-- Logika untuk 'Laporan realisasi' dengan sip_reference -->
                          <div
                            v-if="
                              value_doc_persyaratan.dokumen_permohonan
                                .dokumen_persyaratan_permohonan.dpp_nama ===
                              'Laporan realisasi'
                            "
                          >
                            <!-- Kondisi ketika detail_data.doc_realisasi_ref ada dan memiliki file -->
                            <div
                              v-if="
                                detail_data.doc_realisasi_ref &&
                                detail_data.doc_realisasi_ref.file
                              "
                              class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                              @click="
                                getFile(detail_data.doc_realisasi_ref.file)
                              "
                            >
                              <i class="fa fa-eye"></i> Lihat
                            </div>
                            <!-- Kondisi ketika detail_data.doc_realisasi_ref ada tapi file tidak ada -->
                            <div
                              v-else-if="
                                detail_data.doc_realisasi_ref &&
                                !detail_data.doc_realisasi_ref.file
                              "
                            >
                              Data tidak tersedia
                            </div>
                            <!-- Kondisi ketika detail_data.doc_realisasi_ref tidak ada dan value_doc_persyaratan.file ada -->
                            <div
                              v-else-if="
                                !detail_data.doc_realisasi_ref &&
                                value_doc_persyaratan.file
                              "
                              class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                              @click="getFile(value_doc_persyaratan.file)"
                            >
                              <i class="fa fa-eye"></i> Lihat
                            </div>
                            <!-- Kondisi ketika detail_data.doc_realisasi_ref tidak ada dan value_doc_persyaratan.file juga tidak ada -->
                            <div v-else>Data tidak tersedia</div>
                          </div>
                          <!-- Logika untuk dokumen selain 'Laporan realisasi' -->
                          <template v-else>
                            <div
                              v-if="value_doc_persyaratan.file"
                              class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                              @click="getFile(value_doc_persyaratan.file)"
                            >
                              <i class="fa fa-eye"></i> Lihat
                            </div>
                            <div v-else>Data tidak tersedia</div>
                          </template>
                        </td>
                        <td
                          v-if="
                            permohonan_status.status_permohonan_terbaru
                              .status_permohonan.is_verifikasi_dokumen &&
                            (session_data.roles_id ==
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.roles_id ||
                              session_data.roles_id == 1) &&
                            ((permohonan_status.status_permohonan_terbaru
                              .status_permohonan.is_proses_by_komoditas &&
                              session_data.tanaman_kategori_id ==
                                detail_data.tujuan_penggunaan_benih
                                  .tanaman_kategori_id) ||
                              !permohonan_status.status_permohonan_terbaru
                                .status_permohonan.is_proses_by_komoditas ||
                              session_data.roles_id == 1)
                          "
                        >
                          <template
                            v-if="
                              value_doc_persyaratan.permohonan_dokumen_verifikasi ==
                              ''
                            "
                          >
                            <div :id="'doc-action-' + index_doc_persyaratan">
                              <div
                                :id="
                                  'btn-action-terima-' + index_doc_persyaratan
                                "
                                class="hide be-hidden"
                              >
                                <div
                                  class="btn btn-sm btn-success btn-hover-outline-success text-nowrap"
                                >
                                  <i class="fa fa-check"></i> Diterima
                                </div>
                                <div
                                  class="btn btn-sm btn-info btn-hover-outline-info text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'ajukan',
                                      'terima'
                                    )
                                  "
                                >
                                  <i class="fa fa-refresh"></i> Roleback
                                </div>
                              </div>
                              <div
                                :id="
                                  'btn-action-tolak-' + index_doc_persyaratan
                                "
                                class="hide be-hidden"
                              >
                                <div
                                  class="btn btn-sm btn-danger btn-hover-outline-success text-nowrap"
                                >
                                  <i class="fa fa-times"></i> Ditolak
                                </div>
                                <div
                                  class="btn btn-sm btn-info btn-hover-outline-info text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'ajukan',
                                      'tolak'
                                    )
                                  "
                                >
                                  <i class="fa fa-refresh"></i> Roleback
                                </div>
                              </div>
                              <div
                                :id="
                                  'btn-action-ajukan-' + index_doc_persyaratan
                                "
                                class="hide be-hidden showing"
                              >
                                <button
                                  class="btn btn-sm btn-success btn-hover-outline-success text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'terima'
                                    )
                                  "
                                >
                                  <i class="fa fa-check"></i> Terima
                                </button>
                                <button
                                  class="btn btn-sm btn-danger btn-hover-outline-danger text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'tolak-temp'
                                    )
                                  "
                                >
                                  <i class="fa fa-times"></i> Tolak
                                </button>
                              </div>
                              <div
                                class="hide be-hidden"
                                :id="
                                  'btn-action-loading-' + index_doc_persyaratan
                                "
                              >
                                <i class="fa fa-refresh fa-spin"></i> Loading...
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div :id="'doc-action-' + index_doc_persyaratan">
                              <div
                                v-bind:class="{
                                  showing:
                                    value_doc_persyaratan
                                      .permohonan_dokumen_verifikasi[0]
                                      .status == 'terima',
                                }"
                                :id="
                                  'btn-action-terima-' + index_doc_persyaratan
                                "
                                class="hide be-hidden"
                              >
                                <div
                                  class="btn btn-sm btn-success btn-hover-outline-success text-nowrap"
                                >
                                  <i class="fa fa-check"></i> Diterima
                                </div>
                                <div
                                  class="btn btn-sm btn-info btn-hover-outline-info text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'ajukan',
                                      'terima'
                                    )
                                  "
                                >
                                  <i class="fa fa-refresh"></i> Roleback
                                </div>
                              </div>
                              <div
                                v-bind:class="{
                                  showing:
                                    value_doc_persyaratan
                                      .permohonan_dokumen_verifikasi[0]
                                      .status == 'tolak',
                                }"
                                :id="
                                  'btn-action-tolak-' + index_doc_persyaratan
                                "
                                class="hide be-hidden"
                              >
                                <div
                                  class="btn btn-sm btn-danger btn-hover-outline-success text-nowrap"
                                >
                                  <i class="fa fa-times"></i> Ditolak
                                </div>
                                <div
                                  class="btn btn-sm btn-info btn-hover-outline-info text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'ajukan',
                                      'tolak'
                                    )
                                  "
                                >
                                  <i class="fa fa-refresh"></i> Roleback
                                </div>
                              </div>
                              <div
                                v-bind:class="{
                                  showing:
                                    value_doc_persyaratan.permohonan_dokumen_verifikasi ==
                                      '' ||
                                    value_doc_persyaratan
                                      .permohonan_dokumen_verifikasi[0]
                                      .status == 'ajukan',
                                }"
                                :id="
                                  'btn-action-ajukan-' + index_doc_persyaratan
                                "
                                class="hide be-hidden"
                              >
                                <button
                                  class="btn btn-sm btn-success btn-hover-outline-success text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'terima'
                                    )
                                  "
                                >
                                  <i class="fa fa-check"></i> Terima
                                </button>
                                <button
                                  class="btn btn-sm btn-danger btn-hover-outline-danger text-nowrap"
                                  v-on:click="
                                    verifikasiDokumen(
                                      index_doc_persyaratan,
                                      value_doc_persyaratan.id,
                                      'tolak-temp'
                                    )
                                  "
                                >
                                  <i class="fa fa-times"></i> Tolak
                                </button>
                              </div>
                              <div
                                class="hide be-hidden"
                                :id="
                                  'btn-action-loading-' + index_doc_persyaratan
                                "
                              >
                                <i class="fa fa-refresh fa-spin"></i> Loading...
                              </div>
                            </div>
                          </template>
                        </td>
                        <td :id="'keterangan-doc' + index_doc_persyaratan">
                          <template>
                            <div
                              v-if="
                                value_doc_persyaratan.permohonan_dokumen_verifikasi !=
                                ''
                              "
                            >
                              {{
                                value_doc_persyaratan
                                  .permohonan_dokumen_verifikasi[0]
                                  .pdv_keterangan
                              }}
                            </div>
                            <div v-else></div>
                          </template>
                        </td>
                      </tr>
                      <tr v-if="detail_data.doc_realisasi_ref">
                        <td colspan="5">
                          <h3>Data benih terealisasi</h3>
                          <table class="table table-bordered table-sm w-100">
                            <thead class="bg-sapphire">
                              <tr>
                                <th style="width: 15px">No</th>
                                <th>Jenis Tanaman</th>
                                <th>Nama Varietas</th>
                                <th>Bentuk Benih</th>
                                <th>Jumlah</th>
                                <th>Jumlah Realisasi</th>
                                <th>Jumlah sisa</th>
                                <th>Satuan</th>
                                <th>Nilai (Total Harga dalam USD)</th>
                                <th>Kode HS</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  value_benih, index_benih
                                ) in list_benih_realisasi"
                                :key="index_benih"
                              >
                                <td>{{ index_benih + 1 }}</td>
                                <td>
                                  {{ value_benih.jenis_tanaman }}
                                </td>

                                <td>{{ value_benih.varietas }}</td>
                                <td>{{ value_benih.bentuk_benih }}</td>
                                <td>
                                  {{ value_benih.prsb_jumlah }}
                                </td>
                                <td>
                                  {{ value_benih.prsb_jumlah_realisasi }}
                                </td>
                                <td>
                                  {{
                                    value_benih.prsb_jumlah -
                                    value_benih.prsb_jumlah_realisasi
                                  }}
                                </td>
                                <td>{{ value_benih.nama_satuan }}</td>
                                <td>{{ value_benih.nilai_total }}</td>
                                <td>{{ value_benih.kode_hs }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol md="12">
                  <hr />
                  <h3 class="mb-0 my-3">Dokumen Proses</h3>
                  <table class="table table-sm table-bordered">
                    <thead class="bg-dark text-hite">
                      <tr class="text-center">
                        <th style="width: 15px">No</th>
                        <th>Dokumen Permohonan</th>
                        <th style="width: 100px">File</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Excel Permohonan Benih</td>
                        <td>
                          <div
                            target="_blank"
                            class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                            v-on:click="getExcelBenih()"
                          >
                            <i class="fa fa-download"></i> Unduh
                          </div>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          detail_data.permohonan.permohonan_kelompok_tani
                            .length != 0 &&
                          detail_data.tujuan_penggunaan_benih.id == 3
                        "
                      >
                        <td>2</td>
                        <td>Excel Data Kelompok Tani</td>
                        <td>
                          <div
                            target="_blank"
                            class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                            v-on:click="getExcelKelompokTani()"
                          >
                            <i class="fa fa-download"></i> Unduh
                          </div>
                        </td>
                      </tr>
                      <template>
                        <!-- <tr v-if="detail_data.dokumen_proses == ''">
													<td colspan="3">Dokumen Belum Tersedia</td>
												</tr> -->
                        <tr
                          v-for="(
                            value_doc_proses, index_doc_proses
                          ) in detail_data.dokumen_proses"
                          :key="index_doc_proses"
                        >
                          <td
                            v-if="
                              detail_data.permohonan.permohonan_kelompok_tani
                                .length != 0 &&
                              detail_data.tujuan_penggunaan_benih.id == 3
                            "
                          >
                            {{ index_doc_proses + 3 }}
                          </td>
                          <td v-else>
                            {{ index_doc_proses + 2 }}
                          </td>
                          <td>{{ value_doc_proses.nama_dokumen }}</td>
                          <td>
                            <div
                              target="_blank"
                              class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                              v-on:click="
                                getGenerateFile(value_doc_proses.link)
                              "
                            >
                              <i class="fa fa-eye"></i> Lihat
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </CCol>
                <CCol md="12">
                  <hr />
                  <h3 class="mb-0 my-3">Dokumen Hasil</h3>
                  <table class="table table-sm table-bordered">
                    <thead class="bg-dark text-hite">
                      <tr class="text-center">
                        <th style="width: 15px">No</th>
                        <th>Dokumen Permohonan</th>
                        <th style="width: 100px">File</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template>
                        <tr v-if="detail_data.dokumen_hasil == ''">
                          <td colspan="3">Dokumen Belum Tersedia</td>
                        </tr>
                        <tr
                          v-for="(
                            value_doc_hasil, index_doc_hasil
                          ) in detail_data.dokumen_hasil"
                          :key="index_doc_hasil"
                          v-else
                        >
                          <td>{{ index_doc_hasil + 1 }}</td>
                          <td>{{ value_doc_hasil.nama_dokumen }}</td>
                          <td>
                            <template>
                              <div
                                target="_blank"
                                class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                v-on:click="
                                  getFileWithLink(value_doc_hasil.link)
                                "
                                v-if="value_doc_hasil.link"
                              >
                                <i class="fa fa-eye"></i> Lihat
                              </div>
                              <div v-else>Data Tidak Tersedia</div>
                            </template>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </CCol>
              </CRow>
            </CTab>
            <CTab title="Data Perusahaan">
              <div class="mt-3">
                <template>
                  <CTabs variant="pills" :active-tab="0">
                    <CTab title="Profile">
                      <table
                        class="table table-hover table-striped table-bordered table-sm mt-3"
                      >
                        <tbody>
                          <tr
                            v-for="(
                              value_data_profile_perusahaan,
                              key_data_profile_perusahaan
                            ) in data_profile_perusahaan"
                            :key="key_data_profile_perusahaan"
                          >
                            <th width="350">
                              {{ key_data_profile_perusahaan }}
                            </th>
                            <td>{{ value_data_profile_perusahaan }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CTab>
                    <CTab title="Data Infestasi">
                      <table
                        class="table table-hover table-striped table-bordered table-sm mt-3"
                      >
                        <tbody>
                          <tr
                            v-for="(
                              value_data_infestasi, key_data_infestasi
                            ) in data_infestasi"
                            :key="key_data_infestasi"
                          >
                            <th width="350">{{ key_data_infestasi }}</th>
                            <td>{{ value_data_infestasi }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CTab>
                    <CTab title="Pemegang Saham">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_data_pemegang_saham, key_data_pemegang_saham
                          ) in data_pemegang_saham"
                          v-bind:key="key_data_pemegang_saham"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_data_pemegang_saham
                                  ? false
                                  : key_data_pemegang_saham
                            "
                          >
                            <h5 class="m-0">
                              Pemegang Saham #{{ key_data_pemegang_saham + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse
                            :show="accordion === key_data_pemegang_saham"
                          >
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_data_pemegang_saham_data,
                                      key_data_pemegang_saham_val
                                    ) in value_data_pemegang_saham"
                                    :key="key_data_pemegang_saham_val"
                                  >
                                    <th width="350">
                                      {{ key_data_pemegang_saham_val }}
                                    </th>
                                    <td>
                                      {{ value_data_pemegang_saham_data }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                    <CTab title="Penanggung Jawab">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_penanggung_jawab, key_penanggung_jawab
                          ) in data_penanggung_jawab"
                          v-bind:key="key_penanggung_jawab"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_penanggung_jawab
                                  ? false
                                  : key_penanggung_jawab
                            "
                          >
                            <h5 class="m-0">
                              Penanggung Jawab #{{ key_penanggung_jawab + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse :show="accordion === key_penanggung_jawab">
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_penanggung_jawab_data,
                                      key_penanggung_jawab_data
                                    ) in value_penanggung_jawab"
                                    :key="key_penanggung_jawab_data"
                                  >
                                    <th width="350">
                                      {{ key_penanggung_jawab_data }}
                                    </th>
                                    <td>{{ value_penanggung_jawab_data }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                    <CTab title="Legalitas">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_legalitas, key_legalitas
                          ) in data_legalitas"
                          v-bind:key="key_legalitas"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_legalitas
                                  ? false
                                  : key_legalitas
                            "
                          >
                            <h5 class="m-0">
                              Legalitas #{{ key_legalitas + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse :show="accordion === key_legalitas">
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_legalitas_data, key_legalitas_data
                                    ) in value_legalitas"
                                    :key="key_legalitas_data"
                                  >
                                    <th width="350">
                                      {{ key_legalitas_data }}
                                    </th>
                                    <td>{{ value_legalitas_data }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                    <CTab title="RPTKA Jabatan"> </CTab>
                    <CTab title="RPTKA Negara">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_rptka_negara, key_rptka_negara
                          ) in data_rptka_negara"
                          v-bind:key="key_rptka_negara"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_rptka_negara
                                  ? false
                                  : key_rptka_negara
                            "
                          >
                            <h5 class="m-0">
                              Negara #{{ key_rptka_negara + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse :show="accordion === key_rptka_negara">
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_rptka_negara_data,
                                      key_rptka_negara_data
                                    ) in value_rptka_negara"
                                    :key="key_rptka_negara_data"
                                  >
                                    <th width="350">
                                      {{ key_rptka_negara_data }}
                                    </th>
                                    <td>{{ value_rptka_negara_data }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                    <CTab title="RPTKA Lokasi">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_rptka_lokasi, key_rptka_lokasi
                          ) in data_rptka_lokasi"
                          v-bind:key="key_rptka_lokasi"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_rptka_lokasi
                                  ? false
                                  : key_rptka_lokasi
                            "
                          >
                            <h5 class="m-0">
                              Lokasi #{{ key_rptka_lokasi + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse :show="accordion === key_rptka_lokasi">
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_rptka_lokasi_data,
                                      key_rptka_lokasi_data
                                    ) in value_rptka_lokasi"
                                    :key="key_rptka_lokasi_data"
                                  >
                                    <th width="350">
                                      {{ key_rptka_lokasi_data }}
                                    </th>
                                    <td>{{ value_rptka_lokasi_data }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                    <CTab title="Data DNI">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_data_dni, key_data_dni
                          ) in data_data_dni"
                          v-bind:key="key_data_dni"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_data_dni
                                  ? false
                                  : key_data_dni
                            "
                          >
                            <h5 class="m-0">
                              Data DNI #{{ key_data_dni + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse :show="accordion === key_data_dni">
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_data_dni_data, key_data_dni_data
                                    ) in value_data_dni"
                                    :key="key_data_dni_data"
                                  >
                                    <th width="350">{{ key_data_dni_data }}</th>
                                    <td>{{ value_data_dni_data }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                    <CTab title="Data Checklist">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_data_checklist, key_data_checklist
                          ) in data_data_checklist"
                          v-bind:key="key_data_checklist"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_data_checklist
                                  ? false
                                  : key_data_checklist
                            "
                          >
                            <h5 class="m-0">
                              Data DNI #{{ key_data_checklist + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse :show="accordion === key_data_checklist">
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_data_checklist_data,
                                      key_data_checklist_data
                                    ) in value_data_checklist"
                                    :key="key_data_checklist_data"
                                  >
                                    <th width="350">
                                      {{ key_data_checklist_data }}
                                    </th>
                                    <td>{{ value_data_checklist_data }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                    <CTab title="Data Proyek">
                      <div>
                        <CCard
                          class="mb-0"
                          v-for="(
                            value_data_proyek, key_data_proyek
                          ) in data_data_proyek"
                          v-bind:key="key_data_proyek"
                        >
                          <CButton
                            block
                            color="link"
                            class="text-left shadow-none card-header"
                            @click="
                              accordion =
                                accordion === key_data_proyek
                                  ? false
                                  : key_data_proyek
                            "
                          >
                            <h5 class="m-0">
                              Data Proyek #{{ key_data_proyek + 1 }}
                            </h5>
                          </CButton>
                          <CCollapse :show="accordion === key_data_proyek">
                            <CCardBody>
                              <table
                                class="table table-hover table-striped table-bordered table-sm mt-3"
                              >
                                <tbody>
                                  <tr
                                    v-for="(
                                      value_data_proyek_data,
                                      key_data_proyek_data
                                    ) in value_data_proyek"
                                    :key="key_data_proyek_data"
                                  >
                                    <th width="350">
                                      {{ key_data_proyek_data }}
                                    </th>
                                    <td>
                                      {{ value_data_proyek_data }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </div>
                    </CTab>
                  </CTabs>
                </template>
              </div>
            </CTab>
            <CTab title="History Permohonan">
              <table
                class="table table-hover table-responsive table-bordered table-sm no-footer"
              >
                <thead class="bg-dark text-white">
                  <tr>
                    <th class="align-top" rowspan="2">No</th>
                    <th>Kode Permohonan</th>
                    <!-- <th>NIB Perusahaan</th>
			                            <th>Nama Perusahaan</th>
			                            <th>Pemohon</th> -->
                    <th>Kategori Module</th>
                    <th>Module</th>
                    <th>Nomor Surat</th>
                    <th>Tgl Pengajuan</th>
                    <th>Status</th>
                    <th class="align-top" rowspan="2">Aksi</th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="text"
                        placeholder="Kode Permohonan"
                        class="form-control"
                        v-model="kode"
                      />
                    </td>
                    <!-- <td>
			                                <input type="text" placeholder="NIB Perusahaan" class="form-control" v-model="nib">
			                            </td>
			                            <td>
			                                <input type="text" placeholder="Nama Perusahaan" class="form-control" v-model="perusahaan">
			                            </td>
			                            <td>
			                                <input type="text" placeholder="Pemohon" class="form-control" v-model="pemohon">
			                            </td> -->
                    <td>
                      <input
                        type="text"
                        placeholder="Kategori Module"
                        class="form-control"
                        v-model="pr_kategori"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Module"
                        class="form-control"
                        v-model="m_nama"
                      />
                    </td>
                    <td>
                      <!-- <input
                        type="text"
                        placeholder="Nomor Surat"
                        class="form-control"
                        v-model="pr_nomor_surat"
                      /> -->
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Tgl Pengajuan"
                        class="form-control"
                        v-model="pr_tanggal"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Status"
                        class="form-control"
                        v-model="status_permohonan"
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr v-if="loadingTable">
                      <td colspan="11">
                        <i class="fa fa-spin fa-refresh"></i> Loading...
                      </td>
                    </tr>
                    <tr
                      v-for="(
                        value_list_permohonan, key_list_permohonan
                      ) in list_permohonan"
                      :key="key_list_permohonan"
                      v-else
                    >
                      <td>
                        <template>
                          <div v-if="key_list_permohonan + 1 == 10">
                            {{ curent_page }}0
                          </div>
                          <div v-else>
                            <span v-if="curent_page - 1 != 0">
                              {{ curent_page - 1 }}
                            </span>
                            {{ key_list_permohonan + 1 }}
                          </div>
                        </template>
                      </td>
                      <td>{{ value_list_permohonan.kode }}</td>
                      <!-- <td>{{value_list_permohonan.nib}}</td>
			                                <td>{{value_list_permohonan.perusahaan}}</td>
			                                <td>{{value_list_permohonan.pemohon}}</td> -->
                      <td>{{ value_list_permohonan.pr_kategori }}</td>
                      <td>{{ value_list_permohonan.m_nama }}</td>
                      <td>{{ value_list_permohonan.pr_nomor_surat }}</td>
                      <td>{{ value_list_permohonan.pr_tanggal }}</td>
                      <td>
                        <template>
                          <CBadge
                            :color="
                              getBadge(value_list_permohonan.status_permohonan)
                            "
                            v-if="
                              value_list_permohonan.status_permohonan ==
                              'Pengajuan Permohonan'
                            "
                          >
                            <div>Draft</div>
                          </CBadge>
                          <CBadge
                            :color="
                              getBadge(value_list_permohonan.status_permohonan)
                            "
                            v-else
                          >
                            <div>
                              {{ value_list_permohonan.status_permohonan }}
                            </div>
                          </CBadge>
                        </template>
                      </td>
                      <td>
                        <template>
                          <div v-if="value_list_permohonan.is_pemasukan">
                            <router-link
                              :to="{
                                name: 'DetailPermohonanPemasukan',
                                params: {
                                  idpermohonan: value_list_permohonan.id,
                                },
                              }"
                              class="btn btn-info btn-sm"
                              ><i class="fa fa-eye"></i> Detail</router-link
                            >
                          </div>
                          <div v-else>
                            <router-link
                              :to="{
                                name: 'DetailPermohonanPengeluaran',
                                params: {
                                  idpermohonan: value_list_permohonan.id,
                                },
                              }"
                              class="btn btn-info btn-sm"
                              ><i class="fa fa-eye"></i> Detail</router-link
                            >
                          </div>
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <nav aria-label="pagination">
                <ul class="pagination justify-content-start">
                  <!---->
                  <!-- {{ page.active ? 'active' : '' }} -->
                  <li
                    v-for="(page, key_page) in paging"
                    v-bind:class="{ active: page.active }"
                    :key="key_page"
                    class="page-item"
                  >
                    <div
                      v-on:click="toPage(page.url)"
                      class="page-link c-page-link-number"
                    >
                      <div v-html="page.label"></div>
                    </div>
                  </li>
                </ul>
              </nav>
            </CTab>
            <CTab title="Laporan Relisasi">
              <table class="table table-sm table-bordered">
                <thead class="bg-dark text-white">
                  <tr>
                    <th>No</th>
                    <th>Perusahaan</th>
                    <th>NIB</th>
                    <th>Nomor SIP</th>
                    <th>Nomor Realisasi</th>
                    <th>Tanggal Pengajuan</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <template>
                  <tbody v-if="reslistrealisasi.length != 0">
                    <template>
                      <tr v-if="loadingTableRealisasi">
                        <td colspan="6">
                          <i class="fa fa-spin fa-refresh"></i> Loading...
                        </td>
                      </tr>
                      <tr
                        v-for="(
                          listrealisasi, key_realisasi
                        ) in reslistrealisasi"
                        v-else
                        v-bind:key="key_realisasi"
                      >
                        <td>
                          <!-- {{key_realisasi+1}} -->
                          <template>
                            <div v-if="key_realisasi + 1 == 10">
                              {{ curent_page_realisasi }}0
                            </div>
                            <div v-else>
                              <template>
                                <span v-if="curent_page_realisasi - 1 != 0">
                                  {{ curent_page_realisasi - 1
                                  }}{{ key_realisasi + 1 }}
                                </span>
                                <span v-else>
                                  {{ key_realisasi + 1 }}
                                </span>
                              </template>
                            </div>
                          </template>
                        </td>
                        <td>{{ listrealisasi.p_nama }}</td>
                        <td>{{ listrealisasi.nib }}</td>
                        <td>{{ listrealisasi.prs_nomor_sk }}</td>
                        <td>{{ listrealisasi.prs_nomor }}</td>
                        <td>{{ listrealisasi.prs_tanggal }}</td>
                        <td>
                          <div
                            class="btn btn-info btn-sm"
                            v-on:click="
                              modalRealisasi(listrealisasi.realisasi_id)
                            "
                          >
                            <i class="fa fa-eye"></i> Detail
                          </div>
                        </td>
                        <!-- <td>{{listrealisasi.tanaman.t_nama}}</td>
	                                            <td>{{listrealisasi.tanaman.t_nama_latin}}</td>
	                                            <td>{{listrealisasi.prsb_jumlah}} {{listrealisasi.satuan.s_nama}} </td>
	                                            <td>{{listrealisasi.prsb_jumlah_realisasi}} {{listrealisasi.satuan.s_nama}}</td> -->
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-else>
                    <template>
                      <tr v-if="loadingTable">
                        <td colspan="6">
                          <i class="fa fa-spin fa-refresh"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="7">Data tidak tersedia</td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </table>
              <nav aria-label="pagination">
                <ul class="pagination justify-content-start">
                  <li
                    v-for="(page, key_page) in paging_realisasi"
                    v-bind:class="{ active: page.active }"
                    class="page-item"
                    v-bind:key="key_page"
                  >
                    <div
                      v-on:click="toPageRealisasi(page.url)"
                      class="page-link c-page-link-number"
                    >
                      <div v-html="page.label"></div>
                    </div>
                  </li>
                </ul>
              </nav>
            </CTab>
            <CTab :title="'Laporan Relisasi ' + detail_data.kode_permohonan">
              <table class="table table-sm table-bordered">
                <thead class="bg-dark text-white">
                  <tr>
                    <th>No</th>
                    <th>Perusahaan</th>
                    <th>NIB</th>
                    <th>Nomor SIP</th>
                    <th>Nomor Realisasi</th>
                    <th>Tanggal Pengajuan</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <template>
                  <tbody v-if="reslistrealisasipermohonan.length != 0">
                    <template>
                      <tr v-if="loadingTableRealisasiPermohonan">
                        <td colspan="6">
                          <i class="fa fa-spin fa-refresh"></i> Loading...
                        </td>
                      </tr>
                      <tr
                        v-for="(
                          listrealisasi_permohonan, key_realisasi_permohonan
                        ) in reslistrealisasipermohonan"
                        v-else
                        v-bind:key="key_realisasi_permohonan"
                      >
                        <td>
                          <template>
                            <div v-if="key_realisasi_permohonan + 1 == 10">
                              {{ curent_page_realisasi_permohonan }}0
                            </div>
                            <div v-else>
                              <template>
                                <span
                                  v-if="
                                    curent_page_realisasi_permohonan - 1 != 0
                                  "
                                >
                                  {{ curent_page_realisasi_permohonan - 1
                                  }}{{ key_realisasi_permohonan + 1 }}
                                </span>
                                <span v-else>
                                  {{ key_realisasi_permohonan + 1 }}
                                </span>
                              </template>
                            </div>
                          </template>
                        </td>
                        <td>{{ listrealisasi_permohonan.p_nama }}</td>
                        <td>{{ listrealisasi_permohonan.nib }}</td>
                        <td>{{ listrealisasi_permohonan.prs_nomor_sk }}</td>
                        <td>{{ listrealisasi_permohonan.prs_nomor }}</td>
                        <td>{{ listrealisasi_permohonan.prs_tanggal }}</td>
                        <td>
                          <div
                            class="btn btn-info btn-sm"
                            v-on:click="
                              modalRealisasiPermohonan(
                                listrealisasi_permohonan.realisasi_id
                              )
                            "
                          >
                            <i class="fa fa-eye"></i> Detail
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-else>
                    <template>
                      <tr v-if="loadingTableRealisasiPermohonan">
                        <td colspan="6">
                          <i class="fa fa-spin fa-refresh"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="7">Data tidak tersedia</td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </table>
              <nav aria-label="pagination">
                <ul class="pagination justify-content-start">
                  <li
                    v-for="(page, key_page) in paging_realisasi_permohonan"
                    v-bind:class="{ active: page.active }"
                    class="page-item"
                    v-bind:key="key_page"
                  >
                    <div
                      v-on:click="toPageRealisasi(page.url)"
                      class="page-link c-page-link-number"
                    >
                      <div v-html="page.label"></div>
                    </div>
                  </li>
                </ul>
              </nav>
            </CTab>
          </CTabs>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol md="4">
              <div
                class="text-left"
                v-if="
                  ((session_data.roles_id ==
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.roles_id ||
                    session_data.roles_id == 1) &&
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.id == 7) ||
                  permohonan_status.status_permohonan_terbaru.status_permohonan
                    .id == 40 ||
                  permohonan_status.status_permohonan_terbaru.status_permohonan
                    .id == 71 ||
                  permohonan_status.status_permohonan_terbaru.status_permohonan
                    .id == 107
                "
              >
                <button
                  class="btn btn-info"
                  @click="modalUploadKarantina = true"
                >
                  <i class="fa fa-upload"></i> Upload Dokumen Karantina
                </button>
              </div>
              <div
                v-if="
                  session_data.roles_id == 1 || 
                  permohonan_status.status_permohonan_terbaru.status_permohonan
                    .is_change_status
                "
              >
                <button class="btn btn-info" @click="modalChangeStatus = true">
                  <i class="fa fa-pencil"></i> Ubah Status Permohonan
                </button>
              </div>
            </CCol>
            <CCol md="8">
              <div
                class="text-right"
                v-if="
                  (session_data.roles_id ==
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.roles_id ||
                    session_data.roles_id == 1) &&
                  permohonan_status.status_permohonan_terbaru.status_permohonan
                    .st_nama != 'Selesai' &&
                  permohonan_status.status_permohonan_terbaru.status_permohonan
                    .st_nama != 'Menunggu Perbaikan' &&
                  ((permohonan_status.status_permohonan_terbaru
                    .status_permohonan.is_proses_by_komoditas &&
                    session_data.tanaman_kategori_id ==
                      detail_data.tujuan_penggunaan_benih
                        .tanaman_kategori_id) ||
                    !permohonan_status.status_permohonan_terbaru
                      .status_permohonan.is_proses_by_komoditas ||
                    session_data.roles_id == 1)
                "
              >
                <button
                  class="btn btn-danger"
                  v-on:click="clickVerifikasiTolak('dibatalkan')"
                  v-if="
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.is_reject_void
                  "
                >
                  <i class="fa fa-times"></i> Batalkan Permohonan
                </button>
                <button
                  class="btn btn-warning"
                  v-on:click="clickVerifikasiTolak('ditolak')"
                  v-if="
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.is_reject
                  "
                >
                  <i class="fa fa-times"></i> Tolak Perbaikan
                </button>
                <span
                  v-if="
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.st_nama != 'Dibatalkan' &&
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.is_verifikasi
                  "
                >
                  <button
                    class="btn btn-primary"
                    id="btn-verifikasi"
                    v-if="
                      (permohonan_status.status_permohonan_terbaru
                        .status_permohonan.is_verifikasi_dokumen &&
                        count_jumlah_dokumen == count_jumlah_dokumen_terima) ||
                      !permohonan_status.status_permohonan_terbaru
                        .status_permohonan.is_verifikasi_dokumen
                    "
                    v-on:click="handleButtonClick"
                  >
                    <i class="fa fa-check"></i>
                    <template>
                      <span
                        v-if="
                          permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_proses_dirjen &&
                          !permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_input_sk
                        "
                      >
                        Terbitkan Permohonan
                      </span>
                      <span
                        v-if="
                          permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_proses_dirjen &&
                          permohonan_status.status_permohonan_terbaru
                            .status_permohonan.is_input_sk
                        "
                      >
                        Input No. SK & Terbitkan Permohonan
                      </span>
                      <span v-else> Verifikasi </span>
                    </template>
                  </button>
                </span>
              </div>
              <div
                class="text-right"
                v-if="
                  permohonan_status.status_permohonan_terbaru.status_permohonan
                    .st_kategori_status == 'selesai' &&
                  (session_data.roles_id == 3 || session_data.roles_id == 1)
                "
              >
                <button
                  class="btn btn-danger"
                  v-on:click="clickVerifikasiTolak('dibatalkan')"
                >
                  <i class="fa fa-times"></i> Batalkan Permohonan
                </button>
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>

      <template>
        <div>
          <CModal
            title="Catatan"
            color="btn-primary"
            :show.sync="modalVerifikasi"
          >
            <label>Catatan :</label>
            <textarea
              class="form-control"
              v-model="keterangan_verifikasi"
            ></textarea>
            <template #footer>
              <CButton @click="modalVerifikasi = false" color="secondary"
                >Tutup</CButton
              >
              <CButton
                v-on:click="
                  (alert_verifikasi = 'Proses Verifikasi berhasil'),
                    prosesVerifikasi()
                "
                color="primary"
              >
                <template>
                  <span
                    v-if="
                      permohonan_status.status_permohonan_terbaru
                        .status_permohonan.id == 11
                    "
                  >
                    Terbit
                  </span>
                  <span v-else> Simpan </span>
                </template>
              </CButton>
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal title="Catatan" color="btn-primary" :show.sync="modalTolak">
            <div
              class="form-group"
              v-if="
                permohonan_status.status_permohonan_terbaru.status_permohonan
                  .st_kategori_status == 'selesai'
              "
            >
              <label>Dokumen Pembatalan :</label>
              <input
                type="file"
                class="form-control"
                id="dokumen-pembatalan"
                v-on:change="inputDokumenPembatalan()"
              />
              <div
                class="respond-input-file float-left"
                id="dokumen-pembatalan-loading"
              ></div>
              <small class="float-right">*) Max File 2 MB</small>
            </div>

            <div class="form-group">
              <label>Catatan <span class="text-danger">*</span> </label>
              <textarea
                class="form-control"
                v-model="keterangan_verifikasi"
              ></textarea>
            </div>

            <div class="form-group" v-if="confirm_password">
              <label>Konfirmasi Password</label>
              <input type="password" class="form-control" />
            </div>
            <template #footer>
              <CButton @click="modalTolak = false" color="secondary"
                >Tutup</CButton
              >
              <CButton v-on:click="prosesVerifikasiTolak()" color="primary"
                >Submit</CButton
              >
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            title="Catatan"
            color="btn-primary"
            :show.sync="modalEditNomorRekomendasi"
          >
            <div class="form-group">
              <label>Nomor Rekomendasi Karantina</label>
              <input
                type="text"
                class="form-control"
                v-model="nomor_rekomendasi"
              />
            </div>
            <div class="form-group">
              <label>Tanggal Rekomendasi Karantina</label>
              <vuejs-datepicker
                input-class="form-control no-readonly"
                v-model="tanggal_rekomendasi"
                format="yyyy-MM-dd"
              ></vuejs-datepicker>
            </div>
            <template #footer>
              <CButton
                @click="modalEditNomorRekomendasi = false"
                color="secondary"
                >Tutup</CButton
              >
              <CButton v-on:click="prosesEditNomorKarantina()" color="primary"
                >Submit</CButton
              >
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            title="Keterangan"
            color="btn-primary"
            :show.sync="modalTolakDoc"
          >
            <label>Keterangan :</label>
            <textarea class="form-control" v-model="textdoctolak"></textarea>
            <input type="hidden" id="key_doc" />
            <input type="hidden" id="id_doc" />
            <input type="hidden" id="doc_action" />
            <input type="hidden" id="doc_before" />
            <template #footer>
              <CButton @click="modalTolakDoc = false" color="secondary"
                >Tutup</CButton
              >
              <CButton
                v-on:click="tolakVerifikasiDokumenAction()"
                color="primary"
                >Simpan</CButton
              >
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            title="Daftar Penanggung Jawab Perusahaan"
            color="btn-primary"
            :show.sync="modalPenanggungJawab"
            size="xl"
          >
            <table class="table table-bordered table-sm">
              <thead class="bg-sapphire">
                <tr>
                  <td>No</td>
                  <td>Nomor Identitas</td>
                  <td>Nama Penanggung Jawab</td>
                  <td>Jabatan Penanggung Jawab</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    value_penanggung_jawab, key_penanggung_jawab
                  ) in data_penanggung_jawab"
                  v-bind:key="key_penanggung_jawab"
                >
                  <td>{{ key_penanggung_jawab + 1 }}</td>
                  <td>
                    {{ value_penanggung_jawab.no_identitas_penanggung_jwb }}
                  </td>
                  <td>{{ value_penanggung_jawab.nama_penanggung_jwb }}</td>
                  <td>{{ value_penanggung_jawab.jabatan_penanggung_jwb }}</td>
                </tr>
              </tbody>
            </table>
            <template #footer>
              <CButton @click="modalPenanggungJawab = false" color="secondary"
                >Tutup</CButton
              >
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            title="Perubahan Status Permohonan"
            color="btn-primary"
            :show.sync="modalChangeStatus"
          >
            <div class="form-group">
              <label
                >Status Permohonan <span class="text-danger">*</span></label
              >
              <v-select
                :options="permohonan_status.status_permohonan"
                label="st_nama"
                v-model="action_verifikasi_raw"
                placeholder="Pilih Status Permohonan"
              ></v-select>
            </div>
            <div class="form-group">
              <label>Catatan <span class="text-danger">*</span> </label>
              <textarea
                class="form-control"
                v-model="keterangan_verifikasi"
              ></textarea>
            </div>
            <template #footer>
              <CButton @click="modalChangeStatus = false" color="secondary"
                >Tutup</CButton
              >
              <CButton v-on:click="ubahStatusPermohonan()" color="primary"
                >Simpan</CButton
              >
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            :title="
              permohonan_status.status_permohonan_terbaru.status_permohonan
                .is_proses_dirjen
                ? 'Persetujuan Dirjen'
                : 'Proses Surat Keputusan'
            "
            color="btn-primary"
            :show.sync="modalNotifPemohon"
            size="xl"
          >
            <table class="table table-sm table-bordered table-striped">
              <tbody>
                <tr>
                  <th>Nomor Surat Keputusan</th>
                  <td>:</td>
                  <td>{{ nomor_sk }}</td>
                  <td rowspan="2">
                    <img :src="detail_data.qrcode_sk" style="width: 150px" />
                  </td>
                </tr>
                <tr>
                  <th>Tanggal Surat Keputusan</th>
                  <td>:</td>
                  <td>{{ tanggal_sk }}</td>
                </tr>
                <tr>
                  <td colspan="4" id="table-frame-sk">
                    <object
                      id="frame-sk"
                      :data="preview_path_sk + '#zoom=100'"
                      type="application/pdf"
                      style="width: 100%; min-height: 350px"
                    >
                      <div>No online PDF viewer installed</div>
                    </object>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
                v-model="setuju_surat_keputusan"
              />
              <template>
                <label
                  v-if="
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.is_proses_dirjen
                  "
                  class="form-check-label"
                  >Draft Surat Keputusan (SK) sudah sesuai dan siap untuk
                  diterbitkan</label
                >
                <label v-else class="form-check-label"
                  >Surat Keputusan (SK) sudah sesuai dan siap untuk dinotif ke
                  pemohon</label
                >
              </template>
            </div>
            <template #footer>
              <CButton @click="modalNotifPemohon = false" color="secondary"
                >Tutup</CButton
              >
              <CButton
                v-if="setuju_surat_keputusan"
                v-on:click="
                  (alert_verifikasi = 'Proses Verifikasi berhasil'),
                    prosesNotif()
                "
                color="primary"
              >
                Simpan
              </CButton>
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            title="Detail Realisasi"
            color="btn-primary"
            :show.sync="modal_detailrealisasi"
            size="xl"
          >
            <div class="form-group">
              <table class="w-100" border="0">
                <tr>
                  <th style="width: 250px">Nomor SIP</th>
                  <td style="width: 15px">:</td>
                  <td>{{ detailDataRealisasi.prs_nomor_sk }}</td>
                </tr>
                <tr>
                  <th>Tanggal Pengajuan</th>
                  <td style="width: 15px">:</td>
                  <td>{{ detailDataRealisasi.prs_tanggal }}</td>
                </tr>
                <tr>
                  <th>Perihal</th>
                  <td style="width: 15px">:</td>
                  <td>{{ detailDataRealisasi.prs_perihal }}</td>
                </tr>
                <tr>
                  <th>Keterangan</th>
                  <td style="width: 15px">:</td>
                  <td>{{ detailDataRealisasi.prs_keterangan }}</td>
                </tr>
                <tr v-if="detailDataRealisasi.permohonan_id">
                  <th style="width: 250px">Jenis SDG/Komoditas</th>
                  <td style="width: 15px">:</td>
                  <td v-if="detailDataRealisasi.permohonan.sdg_komoditas">
                    {{ detailDataRealisasi.permohonan.sdg_komoditas.t_nama }}
                  </td>
                </tr>
                <tr v-if="detailDataRealisasi.permohonan_id">
                  <th style="width: 250px">Varietas/Klon/Hibrida</th>
                  <td style="width: 15px">:</td>
                  <td v-if="detailDataRealisasi.permohonan">
                    {{
                      detailDataRealisasi.permohonan.sdg_varietas_klon_hibrida
                    }}
                  </td>
                </tr>
                <tr v-if="detailDataRealisasi.permohonan_id">
                  <th style="width: 250px">Bentuk Benih</th>
                  <td style="width: 15px">:</td>
                  <td v-if="detailDataRealisasi.permohonan.sdg_bentuk_benih">
                    {{
                      detailDataRealisasi.permohonan.sdg_bentuk_benih.bb_nama
                    }}
                  </td>
                </tr>
                <tr v-if="detailDataRealisasi.permohonan_id">
                  <th style="width: 250px">Banyaknya SDG</th>
                  <td style="width: 15px">:</td>
                  <td>{{ detailDataRealisasi.permohonan.sdg_banyaknya }}</td>
                </tr>
                <tr v-if="detailDataRealisasi.permohonan_id">
                  <th style="width: 250px">Satuan SDG</th>
                  <td style="width: 15px">:</td>
                  <td v-if="detailDataRealisasi.permohonan.sdg_satuan">
                    {{ detailDataRealisasi.permohonan.sdg_satuan.s_nama }}
                  </td>
                </tr>
                <tr v-if="detailDataRealisasi.permohonan_id">
                  <th style="width: 250px">Kode HS</th>
                  <td style="width: 15px">:</td>
                  <td>{{ detailDataRealisasi.permohonan.sdg_kode_hs }}</td>
                </tr>
                <tr>
                  <th>Data Benih</th>
                  <td style="width: 15px">:</td>
                  <td>Data Benih Untuk Realisasi</td>
                </tr>
                <tr>
                  <td colspan="3">
                    <table
                      class="table table-bordered table-sm w-100"
                      id="table-benih"
                    >
                      <thead class="bg-sapphire">
                        <tr>
                          <th style="width: 15px">No</th>
                          <th class="d-none">Nama Varietas</th>
                          <th>Jumlah</th>
                          <th>Jumlah Realisasi</th>
                          <th>Jumlah Sisa</th>
                          <!-- <th>Nilai</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            value_benih, index_benih
                          ) in detailDataRealisasi.permohonan_realisasi_benih"
                          :key="index_benih"
                        >
                          <td>{{ index_benih + 1 }}</td>
                          <td class="d-none">
                            {{ value_benih.prsb_varietas }}
                          </td>
                          <td>{{ value_benih.prsb_jumlah }}</td>
                          <td>{{ value_benih.prsb_jumlah_realisasi }}</td>
                          <td>{{ value_benih.prsb_jumlah_sisa }}</td>
                          <!-- <td>{{ value_benih.prsb_nilai_total }}</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </div>

            <template #footer>
              <CButton @click="modal_detailrealisasi = false" color="secondary"
                >Tutup</CButton
              >
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            title="Upload Dokumen Karantina"
            color="btn-primary"
            :show.sync="modalUploadKarantina"
            size="lg"
          >
            <CRow>
              <CCol md="6">
                <div class="form-group">
                  <label>Nomor Rekomendasi Karantina</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="nomor_rekomendasi"
                  />
                  <small
                    ><i class="fa fa-info"></i> Nomor urut generate by sistem,
                    diambil dari nomor terakhir.</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label>Tanggal Rekomendasi Karantina</label>
                  <vuejs-datepicker
                    input-class="form-control no-readonly"
                    v-model="tanggal_rekomendasi"
                    format="yyyy-MM-dd"
                  ></vuejs-datepicker>
                  <small
                    ><i class="fa fa-info"></i> Format Tanggal Rekomendasi
                    Karantina "Tahun-Bulan-Tanggal"</small
                  >
                </div>
              </CCol>
            </CRow>

            <table class="table table-bordered table-sm">
              <Thead>
                <tr>
                  <th>Nama File</th>
                  <th>File</th>
                  <th>
                    <div
                      class="btn btn-success btn-sm"
                      v-on:click="addRowKarantina"
                    >
                      <i class="fa fa-plus"></i>
                    </div>
                  </th>
                </tr>
              </Thead>
              <!-- <tbody v-if="dokumen_proses">
			    		<tr v-for="(value_inputed_karantina, index_inputed_karantina) in dokumen_proses" :key="index_inputed_karantina" v-if="value_inputed_karantina.id || value_inputed_karantina.nama_dokumen == 'Pengantar Teknis' || value_inputed_karantina.nama_dokumen == 'Surat Rekomendasi Badan Karantina'" >
			    			<td>{{value_inputed_karantina.nama_dokumen}}</td>
			    			<td>
			    				<div class="btn btn-success btn-sm" v-on:click="getFileWithLink(value_inputed_karantina.link)"><i class="fa fa-eye"></i> Lihat File</div>
			    			</td>
			    			<td>
			    				<div class="btn btn-sm btn-danger" v-if="value_inputed_karantina.id" v-on:click="deleteExistKarantina(value_inputed_karantina.id)"><i class="fa fa-minus"></i></div>
			    			</td>
			    		</tr>
					</tbody> -->
              <tbody>
                <tr
                  v-for="(
                    value_rows_file_karantina, index_rows_file_karantina
                  ) in rows_file_karantina"
                  :key="index_rows_file_karantina"
                >
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      v-model="value_rows_file_karantina.nama_file_karantina"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      class="form-control"
                      :id="'files-karantina-' + index_rows_file_karantina"
                      v-on:change="
                        inputFileDocumentKarantina(index_rows_file_karantina)
                      "
                    />
                    <div
                      class="respond-input-file float-left"
                      :id="'docloading-' + index_rows_file_karantina"
                    ></div>
                    <small class="float-right">*) Max File 2 MB</small>
                  </td>
                  <td>
                    <div
                      class="btn btn-sm btn-danger"
                      v-on:click="
                        deleteRowKarantina(
                          index_rows_file_karantina,
                          value_rows_file_karantina
                        )
                      "
                    >
                      <i class="fa fa-minus"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            *) Input nama file terlebih dahulu sebelum memilih file.
            <template #footer>
              <CButton @click="modalUploadKarantina = false" color="secondary"
                >Tutup</CButton
              >
              <CButton v-on:click="prosesEditNomorKarantina()" color="primary"
                >Simpan</CButton
              >
            </template>
          </CModal>
        </div>
      </template>
      <template>
        <div>
          <CModal
            title="Input Nomor SK"
            color="btn-primary"
            :show.sync="modalInputSK"
          >
            <label>No. SK :</label>
            <input
              type="text"
              class="form-control"
              placeholder="contoh : 1/Kpts/PI.500/F/06/2024"
              v-model="nomor_sk"
            />
            <br />

            <label>Tanggal SK</label>
            <vuejs-datepicker
              input-class="form-control no-readonly"
              v-model="tanggal_sk"
              format="yyyy-MM-dd"
            ></vuejs-datepicker>

            <template #footer>
              <CButton @click="modalInputSK = false" color="secondary"
                >Tutup</CButton
              >
              <CButton v-on:click="clickVerifikasi('lanjut')" color="primary">
                <template>
                  <span> Terbitkan permohonan </span>
                </template>
              </CButton>
            </template>
          </CModal>
        </div>
      </template>
    </CCol>
    <CCol md="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-md-6">
              <h3 class="mb-0">Linimasa</h3>
            </div>
            <div class="col-md-6 text-right">
              <button
                class="btn btn-info"
                @click="getFileWithLink(detail_data.report_timeline)"
              >
                <i class="fa fa-download"></i> Unduh Linimasa
              </button>
            </div>
          </div>
        </CCardHeader>
        <CCardBody style="overflow: scroll" class="p-2">
          <ul class="timeline timeline-horizontal" style="padding-top: 50px">
            <li
              class="timeline-item"
              v-if="
                permohonan_status.status_permohonan_terbaru.status_permohonan
                  .st_nama != 'Selesai' &&
                permohonan_status.status_permohonan_terbaru.status_permohonan
                  .st_nama != 'Dibatalkan'
              "
            >
              <div class="timeline-badge info">
                <i class="fa fa-clock-o" style="margin-top: 12px"></i>
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <b class="timeline-title">{{
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.roles.name
                  }}</b>
                </div>
                <div class="badge badge-info">
                  {{
                    permohonan_status.status_permohonan_terbaru
                      .status_permohonan.st_nama
                  }}
                </div>
                <br />
              </div>
            </li>
            <li
              class="timeline-item"
              v-for="(value_timelime, index_timelime) in detail_data.permohonan
                .permohonan_timeline"
              :key="index_timelime"
            >
              <template>
                <div
                  class="timeline-badge danger"
                  v-if="
                    value_timelime.status == 'ditolak' ||
                    value_timelime.status == 'dibatalkan'
                  "
                >
                  <i class="fa fa-times" style="margin-top: 12px"></i>
                </div>
                <div class="timeline-badge success" v-else>
                  <i class="fa fa-check" style="margin-top: 12px"></i>
                </div>
              </template>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <b class="timeline-title"
                    >{{ value_timelime.jabatan || value_timelime.users.roles }} :
                    {{ value_timelime.users.name }}</b
                  >
                  <p>
                    <small class="text-muted"
                      ><i class="fa fa-clock-o"></i>
                      {{ value_timelime.pt_tanggal }}</small
                    >
                  </p>
                </div>
                <div class="badge badge-info">
                  {{ value_timelime.pt_status_keterangan }}
                </div>
                <br />
                <div class="timeline-body" v-if="value_timelime.pt_keterangan">
                  <b>Catatan : </b><br />
                  <p>{{ value_timelime.pt_keterangan }}</p>
                </div>
              </div>
            </li>
          </ul>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
const null_array = [];
export default {
  name: "DetailPermohonanPemasukan",
  components: {
    vuejsDatepicker,
  },
  data() {
    return {
      idpermohonan: this.$route.params.idpermohonan,
      detail_data: null_array,
      data_benih: null_array,
      data_profile_perusahaan: null_array,
      data_infestasi: null_array,
      data_pemegang_saham: null_array,
      data_penanggung_jawab: null_array,
      data_legalitas: null_array,
      data_rptka_jabatan: null_array,
      data_rptka_negara: null_array,
      data_rptka_lokasi: null_array,
      data_data_dni: null_array,
      data_data_checklist: null_array,
      data_data_proyek: null_array,
      data_data_member: null_array,
      permohonN_next_status: null_array,
      permohonan_status: null_array,
      modalVerifikasi: false,
      modalInputSK: false,
      modalTolak: false,
      keterangan_verifikasi: "",
      action_verifikasi: "",
      action_verifikasi_raw: "",
      collapse: false,
      cardCollapse: true,
      innerCollapse: false,
      accordion: 0,
      count_jumlah_dokumen: "",
      count_jumlah_dokumen_terima: "",
      count_jumlah_dokumen_tolak: "",
      count_jumlah_dokumen_ajukan: "",
      session_data: JSON.parse(this.session),
      is_verifikasi_dokumen: false,
      textdoctolak: "-",
      modalTolakDoc: false,
      modalUploadKarantina: false,
      rows_file_karantina: [
        {
          id_file_karantina: "",
          nama_file_karantina: "",
          url_file_karantina: "",
        },
      ],
      list_bentuk_benih: [],
      rows_benih_raw: [],
      list_satuan: [],
      list_tanaman: [],
      dokumen_karantina: 0,
      confirm_password: false,
      label_alert_tolak: "",
      alert_verifikasi: "",
      // History Permohonan
      kode: "",
      nib: "",
      perusahaan: "",
      pemohon: "",
      pr_kategori: "",
      m_nama: "",
      pr_nomor_surat: "",
      pr_tanggal: "",
      status_permohonan: "",
      // Lain - Lain
      list_permohonan: null_array,
      paging: null_array,
      curent_page: "",
      loadingTable: false,
      dokumen_proses: "",
      reslistrealisasi: null_array,
      modal_detailrealisasi: false,
      detailDataRealisasi: null_array,
      curent_page_realisasi: "",
      loadingTableRealisasi: "",
      paging_realisasi: null_array,
      // modal edit rekomendasi
      modalEditNomorRekomendasi: false,
      nomor_rekomendasi: "",
      tanggal_rekomendasi: new Date(),
      modalPenanggungJawab: false,
      modalChangeStatus: false,
      modalNotifPemohon: false,
      setuju_surat_keputusan: false,
      preview_path_sk: "",
      nomor_sk: "",
      tanggal_sk: "",
      reslistrealisasipermohonan: null_array,
      loadingTableRealisasiPermohonan: false,
      excel_url_realisasi_permohonan: [],
      paging_realisasi_permohonan: [],
      curent_page_realisasi_permohonan: [],
      type_permohonan_loading: false,
      beforeChange: false,
      list_benih_realisasi: [],
    };
  },

  methods: {
    renderNoReadonly() {
      // alert('123');
      $(".no-readonly").prop("readonly", false);
    },
    ubahStatusPermohonan() {
      if (!this.action_verifikasi_raw?.id || !this.keterangan_verifikasi) {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Pastikan status dan catatan terisi.",
        });
        return false;
      } else {
        this.alert_verifikasi = "Status Permohonan Berhasil Diubah";
        this.action_verifikasi = this.action_verifikasi_raw?.id;
        this.prosesVerifikasi();
      }
    },
    prosesNotif() {
      var text =
        "Permohonan akan dinotifikasi ke pemohon dan status akan selesai.";
      if (
        this.permohonan_status.status_permohonan_terbaru.status_permohonan
          .is_proses_dirjen
      ) {
        text =
          "Surat Keputusan akan diterbitkan dan akan segera di notif ke pemohon";
      }

      Swal.fire({
        title: "Apakah anda yakin?",
        text: text,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Proses`,
        denyButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.prosesVerifikasi();
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },
    modalRealisasi(idrealisasi) {
      var detailData = this.reslistrealisasi.find((detailDatas) => {
        if (detailDatas.id === idrealisasi) {
          return detailDatas;
        }
      });
      this.modal_detailrealisasi = true;
      this.detailDataRealisasi = detailData;
    },
    modalRealisasiPermohonan(idrealisasi) {
      var detailData = this.reslistrealisasipermohonan.find((detailDatas) => {
        if (detailDatas.id === idrealisasi) {
          return detailDatas;
        }
      });
      this.modal_detailrealisasi = true;
      this.detailDataRealisasi = detailData;
      console.log(this.detailDataRealisasi);
    },
    toPageRealisasi(url) {
      this.loadingTableRealisasi = true;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_realisasi = response.data;
          var res_realisasi_data = res_realisasi.data;
          // console.log(res_realisasi_data);
          if (res_realisasi.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_realisasi.data.message,
            });
          } else {
            this.loadingTableRealisasi = false;
            this.reslistrealisasi = res_realisasi_data.data.data;
            this.paging_realisasi = res_realisasi_data.data.links;
            this.curent_page_realisasi = res_realisasi_data.data.current_page;
          }
        });
    },
    getExcelBenih() {
      // var win = window.open("https://simpel2.pertanian.go.id/endpoint/public/api/excel-list-benih?permohonan_id="+this.detail_data.permohonan.id+'&nib='+this.detail_data.permohonan.nib, '_blank');
      var win = window.open(
        this.apiLink +
          "api/excel-list-benih?permohonan_id=" +
          this.detail_data.permohonan.id +
          "&nib=" +
          this.detail_data.permohonan.nib,
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getExcelKelompokTani() {
      // var win = window.open("https://simpel2.pertanian.go.id/endpoint/public/api/excel-list-benih?permohonan_id="+this.detail_data.permohonan.id+'&nib='+this.detail_data.permohonan.nib, '_blank');
      var win = window.open(
        this.apiLink +
          "api/excel-kelompok-tani?permohonan_id=" +
          this.detail_data.permohonan.id +
          "&nib=" +
          this.detail_data.permohonan.nib,
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    prosesEditNomorKarantina() {
      Swal.fire({
        title: "Apakah Anda Yakin?",
        text: "Pastikan nomor dan tanggal rekomendasi terlebih dahulu.",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Proses",
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var d = new Date(this.tanggal_rekomendasi),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          var tgl_rek = [year, month, day].join("-");
          // console.log(tgl_rek);
          // return false;
          let self = this;
          // console.log(this.tanggal_rekomendasi.getFullYear() + "-" + ("0"+(self.tanggal_rekomendasi.getMonth()+1)).slice(-2) + "-" + ("0" + self.tanggal_rekomendasi.getDate()).slice(-2));
          // return false;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Mohon tunggu sebentar...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("permohonan_id", this.idpermohonan);
          data.append("nomor_rekomendasi", this.nomor_rekomendasi);
          data.append("tanggal_rekomendasi", tgl_rek);
          // data.append('tanggal_rekomendasi', self.tanggal_rekomendasi.getFullYear() + "-" + ("0"+(self.tanggal_rekomendasi.getMonth()+1)).slice(-2) + "-" + ("0" + self.tanggal_rekomendasi.getDate()).slice(-2));
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/edit-nomor-rekomendasi",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            var res_ajukan = response.data;
            var res_ajukan_data = res_ajukan.data;
            if (res_ajukan.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res_ajukan.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil disimpan.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.modalEditNomorRekomendasi = false;
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    saveDataBenih(saveas) {
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Mohon tunggu sebentar...",
        showConfirmButton: false,
      });
      var rows_benih = [];
      // console.log(this.rows_benih_raw);
      // return false;
      $.each(this.rows_benih_raw, function (index, val) {
        rows_benih.push({
          id: val.id,
          tanaman_id: val.tanaman_id?.id,
          pb_nama_umum: "-",
          pb_nama_latin: val.pb_nama_latin,
          pb_varietas: val.pb_varietas,
          pb_nama_induk: val.pb_nama_induk,
          bentuk_benih_id: val.bentuk_benih_id?.id,
          pb_jumlah: val.pb_jumlah,
          satuan_id: val.satuan_id?.id,
          pb_nilai_total: val.pb_nilai_total,
          pb_kode_hs: val.pb_kode_hs,
          is_aropt: val.is_aropt,
          pb_jumlah_disetujui: val.pb_jumlah_disetujui,
        });
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("permohonan_id", this.idpermohonan);
      data.append("permohonan_benih", JSON.stringify(rows_benih));
      var config = {
        method: "post",
        url: this.apiLink + "api/permohonan/update_benih",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        data: data,
      };
      axios(config).then(function (response) {
        var res_ajukan = response.data;
        var res_ajukan_data = res_ajukan.data;
        if (res_ajukan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_ajukan.data.message,
          });
        } else {
          let timerInterval;
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data benih berhasil disimpan.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            didOpen: () => {
              timerInterval = setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
            }
          });
        }
      });
    },
    saveKarantina() {
      this.modalUploadKarantina = false;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      let timerInterval;
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Upload File Karantina Berhasil",
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location.reload();
        }
      });
    },
    deleteExistKarantina(idkarantina) {
      var detailData = this.dokumen_proses.find((detailDatas) => {
        if (detailDatas.id === idkarantina) {
          return detailDatas;
        }
      });

      var kyes = Object.keys(this.dokumen_proses).find(
        (key) => this.dokumen_proses[key] === detailData
      );

      // console.log(kyes);
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Anda tidak bisa mengembalikan data yang dihapus!",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Hapus`,
        denyButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", idkarantina);
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/delete_rekomendasi",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then((response) => {
            var res_data = response.data;
            // console.log(res_data);
            if (res_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  this.dokumen_proses.splice(kyes, 1);
                }
              });
            }
          });
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },
    addRowKarantina() {
      this.rows_file_karantina.push({
        id_file_karantina: "",
        nama_file_karantina: "",
        url_file_karantina: "",
      });
    },
    inputDokumenPembatalan() {
      if ($("#dokumen-pembatalan")[0].files[0]) {
        if ($("#dokumen-pembatalan")[0].files[0].size < 2242880) {
          $("#dokumen-pembatalan-loading").html(
            '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = this.apiLink;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#dokumen-pembatalan")[0].files[0]);
          data.append("permohonan_id", this.idpermohonan);
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/uploads_pembatalan",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            urlres += response.data.data.url;
            $("#dokumen-pembatalan-loading").html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    inputFileDocumentKarantina(keys) {
      if ($("#files-karantina-" + keys)[0].files[0]) {
        if ($("#files-karantina-" + keys)[0].files[0].size < 2242880) {
          $("#docloading-" + keys).html(
            '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = this.apiLink;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#files-karantina-" + keys)[0].files[0]);
          data.append(
            "pr_nama",
            this.rows_file_karantina[keys].nama_file_karantina
          );
          data.append("permohonan_id", this.idpermohonan);
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/uploads_rekomendasi",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            urlres += response.data.data.url;
            $("#docloading-" + keys).html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
          });
          this.permohonan_dokumen[keys] = permohonan_dokumen_in;
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    deleteRowKarantina(index, rows_file_karantina_at) {
      var idx = this.rows_file_karantina.indexOf(rows_file_karantina_at);
      if (idx > -1) {
        this.rows_file_karantina.splice(idx, 1);
      }
    },
    getFile(files) {
      //var win = window.open(this.apiLink + files, "_blank");
      let modifiedUrl = files.replace(/^\//, "");
      var win = window.open(this.apiLink + modifiedUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getFileWithLink(files) {
      var win = window.open(files, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getGenerateFile(files) {
      console.log(files);
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var url = new URL(files);
      var dokumen = url.searchParams.get("dokumen");
      if (dokumen) {
        axios
          .get(this.apiLink + "api/generate/dokumen_permohonan_preview", {
            params: {
              dokumen: url.searchParams.get("dokumen"),
              permohonan_id: url.searchParams.get("permohonan_id"),
            },
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
          })
          .then((response) => {
            var res_dokumen = response.data;
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan dialihkan ke halaman pdf",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.getFileWithLink(res_dokumen.data);
              }
            });
          })
          .catch((error) => {
            if (error) {
              if (error?.response) {
                var htmlerror = "";
                if (
                  typeof error.response.data.data.error === "string" ||
                  error.response.data.data.error instanceof String
                ) {
                  var htmlerror = error.response.data.data.error;
                } else {
                  $.each(error.response.data.data.error, function (index, val) {
                    htmlerror += val[0] + "<br>";
                  });
                }

                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  html: htmlerror,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Gagal, Silahkan cek koneksi anda !",
                });
              }
            }
          });
      } else {
        this.getFileWithLink(files);
      }
    },
    tolakVerifikasiDokumen(key_doc, id_doc, doc_action, doc_before = "") {
      $("#key_doc").val(key_doc);
      $("#id_doc").val(id_doc);
      $("#doc_action").val(doc_action);
      $("#doc_before").val(doc_before);
      this.modalTolakDoc = true;
    },
    verifikasiDokumen(key_doc, id_doc, doc_action, doc_before = "") {
      // alert($('#doc-action-'+key_doc+' .be-hidden').length);
      if (doc_action == "tolak-temp") {
        this.tolakVerifikasiDokumen(key_doc, id_doc, doc_action, doc_before);
      } else {
        $("#doc-action-" + key_doc + " .be-hidden").removeClass("showing");
        $("#btn-action-loading-" + key_doc).addClass("showing");
        // alert(doc_action);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("permohonan_dokumen_id", id_doc);
        data.append(
          "status_permohonan_id",
          this.permohonan_status.status_permohonan_terbaru.id
        );
        data.append("status", doc_action);
        data.append("pdv_keterangan", this.textdoctolak);
        var config = {
          method: "post",
          url: this.apiLink + "api/permohonan/persetujuan_dokumen_verifikasi",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
          data: data,
        };
        axios(config).then((response) => {
          var terima = this.count_jumlah_dokumen_terima;
          var tolak = this.count_jumlah_dokumen_tolak;
          var ajukan = this.count_jumlah_dokumen_ajukan;
          if (doc_action == "terima") {
            terima++;
            ajukan--;
          } else if (doc_action == "tolak") {
            tolak++;
            ajukan--;
          } else {
            ajukan++;
            if (doc_before == "terima") {
              terima--;
            } else if (doc_before == "tolak") {
              tolak--;
            }
          }
          this.count_jumlah_dokumen_terima = terima;
          this.count_jumlah_dokumen_tolak = tolak;
          this.count_jumlah_dokumen_ajukan = ajukan;
          $("#doc-action-" + key_doc + " .be-hidden").removeClass("showing");
          $("#btn-action-" + doc_action + "-" + key_doc).addClass("showing");
          $("#keterangan-doc" + key_doc).html(this.textdoctolak);
          this.textdoctolak = "-";
          // var res_permohonan_doc_verification = response.data;
          // var res_permohonan_doc_verification_data = res_permohonan_doc_verification_data.data;
          // if (res_permohonan_doc_verification.meta.code != "200") {
          // 	Swal.fire({
          // 		icon: "error",
          // 		title: "Oops...",
          // 		text: res_permohonan_doc_verification.data.message,
          // 	});
          // } else {
          // }
        });
      }
    },
    tolakVerifikasiDokumenAction() {
      var key_doc = $("#key_doc").val();
      var id_doc = $("#id_doc").val();
      var doc_before = $("#doc_before").val();
      this.modalTolakDoc = false;
      this.verifikasiDokumen(key_doc, id_doc, "tolak", doc_before);
    },
    clickVerifikasi(action) {
      let self = this;
      console.log("masuk verifikasi");
      if (
        this.permohonan_status.status_permohonan_sebelumnya.is_proses_dirjen ||
        this.permohonan_status.status_permohonan_terbaru.status_permohonan
          .is_proses_dirjen
      ) {
        // if (this.preview_path_sk == null || this.preview_path_sk == "") {
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          var rows_kode = [];
          rows_kode.push({
            kode_permohonan: this.detail_data.kode_permohonan,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("kode_permohonan", JSON.stringify(rows_kode));
          data.append("generate_from", "detail_permohonan");
          data.append("noSK", this.nomor_sk);
          data.append("tglSK", this.tanggal_sk);

          var config = {
            method: "post",
            url: this.apiLink + "api/autogen",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              var res_data = response.data;
              // console.log(res_data);
              if (res_data.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_data.data.message,
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Generate SK Berhasil",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector("b");
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    self.preview_path_sk =
                      self.apiLink +
                      "storage/permohonan/" +
                      self.detail_data.permohonan.id +
                      "/surat_keputusan.pdf";
                    self.nomor_sk = res_data.data[0].no_sk;

                    self.tanggal_sk = res_data.data[0].tgl_sk;
                    self.modalNotifPemohon = true;
                    self.modalInputSK = false;
                  }
                });
              }
            })
            .catch((error) => {
              if (error) {
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Proses generate SIP gagal, Mohon coba lagi beberapa saat lagi.",
                });
              }
            });
        // } else {
        //  this.modalNotifPemohon = true;
        //}
      } else {
        this.modalVerifikasi = true;
      }
      if (action == "lanjut") {
        this.action_verifikasi =
          this.permohonan_status.status_permohonan_selanjutnya.id;
      }
    },
    clickVerifikasiTolak(action) {
      if (action == "dibatalkan") {
        this.confirm_password = true;
        this.label_alert_tolak = "Proses Pembatalan Permohonan berhasil";
      } else {
        this.label_alert_tolak = "Proses Pengembalian Permohonan berhasil";
        var docver =
          this.count_jumlah_dokumen_terima + this.count_jumlah_dokumen_tolak;
        if (
          this.permohonan_status.status_permohonan_terbaru.status_permohonan
            .is_verifikasi_dokumen &&
          docver != this.count_jumlah_dokumen
        ) {
          Swal.fire({
            icon: "info",
            text: "Verifikasi Dokumen Permohonan Terlebih Dahulu",
          });
          return false;
        }
      }
      this.modalTolak = true;
      this.action_verifikasi = action;
    },
    prosesVerifikasi() {
      this.modalVerifikasi = false;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var toprocess = false;
      var gendoc = "";
      if (
        // hpt persetujuan ka pusat pvtpp generate surat pengantar
        this.permohonan_status.status_permohonan_terbaru.status_permohonan.id ==
        171
      ) {
        gendoc = "surat_pengantar";
      } else if (
        this.permohonan_status.status_permohonan_terbaru.status_permohonan
          .is_generate_sk
      ) {
        gendoc = "surat_keputusan";
      } else {
        gendoc = "";
        toprocess = true;
      }
      console.log(gendoc);
      if (gendoc != "") {
        var FormDatadoc = require("form-data");
        var datadoc = new FormDatadoc();
        datadoc.append("permohonan_id", this.idpermohonan);
        datadoc.append("dokumen", gendoc);
        var config = {
          method: "post",
          url: this.apiLink + "api/generate/dokumen",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
          data: datadoc,
        };
        axios(config).then((response) => {
          var res_gendoc = response.data;
          var res_gendoc_data = res_gendoc.data[0];
          if (res_gendoc.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_gendoc.data.message,
            });
          } else {
            var FormData = require("form-data");
            var data = new FormData();
            data.append("permohonan_id", this.idpermohonan);
            data.append("pt_keterangan", this.keterangan_verifikasi);
            data.append("status_permohonan_id", this.action_verifikasi);
            var config = {
              method: "post",
              url: this.apiLink + "api/permohonan/persetujuan_permohonan",
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
              data: data,
            };
            axios(config).then((response) => {
              var res_permohonan_update_status = response.data;
              var res_permohonan_update_status_data =
                res_permohonan_update_status.data[0];
              if (res_permohonan_update_status.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_permohonan_update_status.data.message,
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: this.alert_verifikasi,
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector("b");
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    window.location.reload();
                  }
                });
              }
            });
          }
        });
      }
      if (toprocess) {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("permohonan_id", this.idpermohonan);
        data.append("pt_keterangan", this.keterangan_verifikasi);
        data.append("status_permohonan_id", this.action_verifikasi);
        var config = {
          method: "post",
          url: this.apiLink + "api/permohonan/persetujuan_permohonan",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
          data: data,
        };
        axios(config).then((response) => {
          var res_permohonan_update_status = response.data;
          var res_permohonan_update_status_data =
            res_permohonan_update_status.data[0];
          if (res_permohonan_update_status.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_permohonan_update_status.data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: this.alert_verifikasi,
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
          }
        });
      }
    },
    prosesVerifikasiTolak() {
      this.modalTolak = false;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      if (
        this.keterangan_verifikasi == "" ||
        this.keterangan_verifikasi == null
      ) {
        Swal.fire({
          icon: "error",
          title: "Pastikan Catatan Terisi",
        });
        return false;
      }
      data.append("permohonan_id", this.idpermohonan);
      data.append("pt_keterangan", this.keterangan_verifikasi);
      data.append("status", this.action_verifikasi);
      var config = {
        method: "post",
        url: this.apiLink + "api/permohonan/pembatalan_permohonan",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        data: data,
      };
      axios(config).then((response) => {
        var res_permohonan_update_status = response.data;
        var res_permohonan_update_status_data =
          res_permohonan_update_status.data[0];
        if (res_permohonan_update_status.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan_update_status.data.message,
          });
        } else {
          let timerInterval;
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: this.label_alert_tolak,
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            didOpen: () => {
              timerInterval = setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.reload();
            }
          });
        }
      });
    },
    toPage(url) {
      this.loadingTable = true;
      // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
      axios
        .get(url, {
          params: {
            is_pemasukan: this.is_pemasukan,
            start: this.start,
            end: this.end,
            status: "selesai",
            nib: this.detail_data.perusahaan.nib,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_permohonan = response.data;
          var res_permohonan_data = res_permohonan.data[0];
          // console.log(res_permohonan_data);
          if (res_permohonan.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_permohonan.data.message,
            });
          } else {
            this.loadingTable = false;
            this.list_permohonan = res_permohonan_data.data;
            this.paging = res_permohonan_data.links;
            this.curent_page = res_permohonan_data.current_page;
          }
        });
    },
    getBadge(status) {
      switch (status) {
        case "draft":
          return "bg-dark";
        case "Menunggu Perbaikan":
          return "warning";
        case "Ditolak":
          return "danger";
        case "Selesai":
          return "success";
        case "ajukan":
          return "info";
        case "menunggu perbaikan":
          return "warning";
        case "Dibatalkan":
          return "danger";
        default:
          return "info";
      }
    },
    normalizeUrl(url) {
      // Mencari semua instans dua slash atau lebih dan menggantinya dengan satu slash,
      // kecuali dua slash setelah protokol (http:, https:, file:, dan lain-lain).
      return url.replace(/([^:])\/{2,}/g, "$1/");
    },

    changeSistem(sip) {
      this.data_benih = [];
      this.type_permohonan_loading = true;
      this.beforeChange = true;
      axios
        .get(this.apiLink + "api/permohonan_realisasi/permohonan_module", {
          params: {
            module: "Benih Tanaman Pakan Ternak",
            sip: sip,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          this.type_permohonan_loading = false;
          var res_perohonan = response.data;
          var res_perohonan_data = res_perohonan.data;

          if (res_perohonan.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_perohonan.data.message,
            });
          } else {
            Swal.close();
            //this.list_no_sk = res_perohonan_data.permohonan;
            var raw_benih_realisasi = [];

            res_perohonan_data.permohonan.forEach((permohonan) => {
              permohonan.permohonan_benih.forEach((val) => {
                // Pastikan untuk mengonversi string ke number sebelum melakukan operasi matematika
                const jumlahDisetujui =
                  parseInt(val.pb_jumlah_disetujui, 10) || 0; // Menggunakan 0 sebagai fallback jika parsing gagal
                const jumlahRealisasi = parseInt(val.jumlah_realisasi, 10) || 0; // Sama di sini

                raw_benih_realisasi.push({
                  permohonan_benih_id: val.id,
                  jenis_tanaman:
                    val.tanaman.t_nama + " - " + val.tanaman.t_nama_latin,
                  varietas: val.pb_varietas,
                  bentuk_benih: val.bentuk_benih.bb_nama,
                  nama_satuan: val.satuan.s_nama,
                  nilai_total: val.pb_nilai_total,
                  kode_hs: val.pb_kode_hs,
                  prsb_jumlah: val.pb_jumlah,
                  prsb_jumlah_realisasi: val.jumlah_realisasi,
                  prsb_jumlah_sisa: jumlahDisetujui - jumlahRealisasi,
                });
              });
            });
            this.list_benih_realisasi = raw_benih_realisasi;
            console.log("raw_benih_realisasi", raw_benih_realisasi);
          }
        })
        .catch((error) => {
          if (error.response.data.data.error == "Permohonan tidak ditemukan") {
            this.type_permohonan_loading = false;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.data.error,
            });
          }
        });
    },

    handleButtonClick() {
      if (
        this.permohonan_status.status_permohonan_sebelumnya.is_proses_dirjen ||
        (this.permohonan_status.status_permohonan_terbaru.status_permohonan
          .is_proses_dirjen &&
          this.permohonan_status.status_permohonan_terbaru.status_permohonan
            .is_input_sk)
      ) {
        this.showModal();
      } else {
        this.clickVerifikasi("lanjut");
      }
    },
    showModal() {
      // Logika untuk menampilkan modal
      this.modalInputSK = true;
      console.log("Menampilkan modal...");
    },
  },
  created() {
    Swal.fire({
      title: '<i class="fa fa-refresh fa-spin"></i>',
      text: "Mohon tunggu sebentar...",
      showConfirmButton: false,
    });
    // console.log(this.access_token);
    axios
      .get(this.apiLink + "api/permohonan/detail", {
        params: {
          id: this.idpermohonan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_permohonan = response.data;
        var res_permohonan_data = res_permohonan.data;
        if (res_permohonan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan.data.message,
          });
        } else {
          swal.close();
          this.detail_data = res_permohonan_data;
          console.log(
            "DETAIL DATA : ",
            res_permohonan_data.permohonan.sip_reference
          );
          if (res_permohonan_data.permohonan.sip_reference) {
            this.changeSistem(res_permohonan_data.permohonan.sip_reference);
          }
          this.nomor_rekomendasi = res_permohonan_data.permohonan.pr_nomor_sr;
          this.tanggal_rekomendasi =
            res_permohonan_data.permohonan.pr_tanggal_sr;
          this.dokumen_proses = res_permohonan_data.dokumen_proses;
          this.data_benih = res_permohonan_data.permohonan_benih;
          var benihdinamis = [];
          $.each(
            res_permohonan_data.permohonan.permohonan_benih,
            function (index, val) {
              benihdinamis.push({
                id: val.id,
                tanaman_id: val.tanaman,
                pb_nama_umum: "-",
                pb_nama_latin: val.pb_nama_latin,
                pb_varietas: val.pb_varietas,
                pb_nama_induk: val.pb_nama_induk,
                bentuk_benih_id: val.bentuk_benih,
                pb_jumlah: val.pb_jumlah,
                satuan_id: val.satuan,
                pb_nilai_total: val.pb_nilai_total,
                pb_kode_hs: val.pb_kode_hs,
                is_aropt: val.is_aropt,
                pb_jumlah_disetujui: val.pb_jumlah_disetujui,
                pb_hibrida_inbrida_komposit: val.pb_hibrida_inbrida_komposit,
                is_select_hibrida: val?.tanaman?.is_select_hibrida,
              });
            }
          );
          var fileKarantina = 0;
          $.each(res_permohonan_data.dokumen_proses, function (index, val) {
            // console.log(val);
            if (val.jenis_dokumen == "Dokumen Karantina") {
              fileKarantina++;
            }
          });
          this.dokumen_karantina = fileKarantina;
          this.rows_benih_raw = benihdinamis;
          var json_perusahaan = JSON.parse(
            res_permohonan_data.perusahaan.p_json
          );
          var all = 0;
          var terima = 0;
          var tolak = 0;
          var ajukan = 0;
          $.each(
            res_permohonan_data.permohonan.permohonan_dokumen,
            function (index, val) {
              all++;
              if (val.permohonan_dokumen_verifikasi == "") {
                ajukan++;
              } else {
                if (val.permohonan_dokumen_verifikasi[0].status == "terima") {
                  terima++;
                }
                if (val.permohonan_dokumen_verifikasi[0].status == "tolak") {
                  tolak++;
                }
              }
            }
          );
          this.count_jumlah_dokumen = all;
          this.count_jumlah_dokumen_terima = terima;
          this.count_jumlah_dokumen_tolak = tolak;
          this.count_jumlah_dokumen_ajukan = ajukan;
          if (json_perusahaan?.responinqueryNIB) {
            var datanibperusahaan = json_perusahaan.responinqueryNIB.dataNIB;
            var clear_data_perusahaan = {};
            $.each(datanibperusahaan, function (index, val) {
              if ($.isArray(val) || val instanceof jQuery) {
              } else {
                clear_data_perusahaan[index] = val;
              }
            });

            this.data_profile_perusahaan = clear_data_perusahaan;
            this.data_pemegang_saham = datanibperusahaan.pemegang_saham;
            this.data_penanggung_jawab = datanibperusahaan.penanggung_jwb;
            this.data_legalitas = datanibperusahaan.legalitas;
            this.data_rptka_negara = datanibperusahaan.data_rptka.rptka_negara;
            this.data_rptka_lokasi = datanibperusahaan.data_rptka.rptka_lokasi;
            this.data_data_dni = datanibperusahaan.data_dni;
            this.data_data_checklist = datanibperusahaan.data_checklist;
            this.data_data_proyek = datanibperusahaan.data_proyek;
          }
          axios
            .get(this.apiLink + "api/master/tanaman", {
              params: {},
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              var res_tanaman = response.data;
              var res_tanaman_data = res_tanaman.data;
              if (res_tanaman.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_tanaman.data.message,
                });
              } else {
                var raw_tanaman = res_tanaman_data.master;
                var clear_tanaman = [];
                var nama_module = res_permohonan_data.permohonan.pr_kategori;
                $.each(raw_tanaman, function (index_kategori, val_kategori) {
                  $.each(
                    val_kategori.tanaman_kategori_sub,
                    function (index_sub_kategori, val_sub_kategori) {
                      var sub_kategori = val_sub_kategori.tanaman_kategori_nama;
                      if (
                        sub_kategori.toLowerCase() == nama_module.toLowerCase()
                      ) {
                        $.each(
                          val_sub_kategori.tanaman,
                          function (index_tanaman, val_tanaman) {
                            clear_tanaman[val_tanaman.id] =
                              val_sub_kategori.tanaman[index_tanaman];
                          }
                        );
                      }
                    }
                  );
                });
                var clear_tanaman = clear_tanaman.filter(function (el) {
                  return el != null;
                });
                this.list_tanaman = clear_tanaman;
              }
              // History Permohonan
              this.loadingTable = true;
              axios
                .get(this.apiLink + "api/permohonan", {
                  params: {
                    is_pemasukan: this.is_pemasukan,
                    start: this.start,
                    end: this.end,
                    status: "selesai",
                    nib: res_permohonan_data.perusahaan.nib,
                  },
                  headers: {
                    Authorization: "Bearer " + this.access_token,
                  },
                })
                .then((response) => {
                  var res_permohonan = response.data;
                  var res_permohonan_data = res_permohonan.data[0];
                  // console.log(res_permohonan_data);
                  if (res_permohonan.meta.code != "200") {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: res_permohonan.data.message,
                    });
                  } else {
                    this.loadingTable = false;
                    this.list_permohonan = res_permohonan_data.data;
                    this.paging = res_permohonan_data.links;
                    this.curent_page = res_permohonan_data.current_page;
                  }
                });
            });
          axios
            .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
              params: {
                nib: res_permohonan_data.perusahaan.nib,
              },
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              var res_realisasi = response.data;
              var res_realisasi_data = res_realisasi.data;
              // console.log(res_realisasi_data);
              if (res_realisasi.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_realisasi.data.message,
                });
              } else {
                this.loadingTableRealisasi = false;
                this.reslistrealisasi = res_realisasi_data.data.data;
                this.excel_url =
                  "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi?nib=" +
                  res_permohonan_data?.perusahaan?.nib;
                this.paging_realisasi = res_realisasi_data.data.links;
                this.curent_page_realisasi =
                  res_realisasi_data.data.current_page;
              }
            });
          axios
            .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
              params: {
                nib: res_permohonan_data.perusahaan.nib,
                permohonan_id: res_permohonan_data.permohonan.id,
              },
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              var res_realisasi_permohonan = response.data;
              var res_realisasi_permohonan_data = res_realisasi_permohonan.data;
              // console.log(res_realisasi_permohonan_data);
              if (res_realisasi_permohonan.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_realisasi_permohonan.data.message,
                });
              } else {
                this.loadingTableRealisasiPermohonan = false;
                this.reslistrealisasipermohonan =
                  res_realisasi_permohonan_data.data.data;
                this.excel_url_realisasi_permohonan =
                  "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi?nib=" +
                  res_permohonan_data?.perusahaan?.nib;
                this.paging_realisasi_permohonan =
                  res_realisasi_permohonan_data.data.links;
                this.curent_page_realisasi_permohonan =
                  res_realisasi_permohonan_data.data.current_page;
                console.log(this.reslistrealisasipermohonan);
              }
            });
        }
      });
    // Get Process Status
    axios
      .get(this.apiLink + "api/permohonan/status", {
        params: {
          id: this.idpermohonan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_permohonan_status = response.data;
        var res_permohonan_status_data = res_permohonan_status.data;
        if (res_permohonan_status.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan_status.data.message,
          });
        } else {
          this.permohonan_status = res_permohonan_status_data;
        }
      });
    axios
      .get(this.apiLink + "api/master/bentuk_benih", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_bentuk_benih = response.data;
        var res_bentuk_benih_data = res_bentuk_benih.data;
        if (res_bentuk_benih.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_bentuk_benih.data.message,
          });
        } else {
          this.list_bentuk_benih = res_bentuk_benih_data.master;
        }
      });
    axios
      .get(this.apiLink + "api/master/satuan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_satuan = response.data;
        var res_satuan_data = res_satuan.data;
        if (res_satuan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_satuan.data.message,
          });
        } else {
          this.list_satuan = res_satuan_data.master;
        }
      });
  },
  computed: {
    sortedPermohonanDokumen() {
      if (!this.detail_data.permohonan.permohonan_dokumen) return [];

      // Mengkloning array untuk mencegah modifikasi array asli
      let dokumen = [...this.detail_data.permohonan.permohonan_dokumen];

      // Memindahkan 'Laporan realisasi' ke akhir
      dokumen.sort((a, b) => {
        let nameA =
          a.dokumen_permohonan.dokumen_persyaratan_permohonan.dpp_nama;
        let nameB =
          b.dokumen_permohonan.dokumen_persyaratan_permohonan.dpp_nama;

        if (nameA === "Laporan realisasi") return 1;
        if (nameB === "Laporan realisasi") return -1;
        return 0;
      });

      return dokumen;
    },
    totalPbJumlah() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      return this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_jumlah),
        0
      );
    },
    totalPbJumlahDisetujui() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      return this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_jumlah_disetujui),
        0
      );
    },
    totalPbNilaiTotal() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      return this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_nilai_total),
        0
      );
    },
  },
};
</script>
